import React, { useState } from 'react';

import PillsList from './PillsListComponent';
import KeywordHitEvolution from './Keywords/KeywordHitEvolution';
import IssueOverallSentimentBar from './IssueOverallSentimentBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const networking = require('../Networking/API');

function IssueComponent({ reviews, issues, limit, hideKeywords, showDetails, handler }: any) {
    const revs = reviews;

    var a: any[] = [];
    var count = [];

    function updateToState(issue: any, e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        networking.updateIssueState(issue, issue.keywords.join(), value).then((issue: any) => {
            console.log('UPDATED STATE');

            handler(true);
        }).catch((error: Error) => {
            console.log(error.stack);
        });
    }

    issues.map((issue: any) =>
        a.push({
            'r': revs.filter((r: any) => issue.keywords.some((k: string) => r.content.toLowerCase().includes(k.toLocaleLowerCase()))),
            'title': issue.title,
            'keywords': issue.keywords,
            'state': issue.state,
            'id': issue.id,
            'className': issue.state == 'Closed' ? "btn btn-success btn-sm dropdown-toggle" : issue.state == 'New' ? "btn btn-danger btn-sm dropdown-toggle" : "btn btn-primary btn-sm dropdown-toggle"
        })
    );

    a.sort((a, b) => (a.r.length < b.r.length) ? 1 : -1);

    const distribution = a.slice(0, limit).map((issue: any, index: number) =>

        <div>
            {issue.r.length > 0 &&
                <div className='row'>
                    <div className='col-2 my-auto'>
                        <p className='iron semi-bold'>{issue.title}</p>
                    </div>
                    <div className='col-4 my-auto ps-5 pe-5'>
                        <IssueOverallSentimentBar reviews={issue.r} />
                    </div>
                    <div className='col my-auto text-end'>
                        <p className='light-iron semi-bold hint'>{issue.r.length} hits</p>
                        {hideKeywords === false &&
                            <div className='col-auto'>
                                <PillsList keywords={issue.keywords} />
                            </div>
                        }
                    </div>
                    <div className='col-auto my-auto'>
                        <KeywordHitEvolution reviews={issue.r} preview={true} />
                    </div>
                    <div className='col-auto my-auto text-end'>
                        <div className="btn-group">

                            <button className={issue.className} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {issue.state}
                            </button>
                            <ul className="dropdown-menu">
                                <li><a onClick={(e) => updateToState(issue, e)} className="dropdown-item">Follow</a></li>
                                <li><a onClick={(e) => updateToState(issue, e)} className="dropdown-item">Assigned</a></li>
                                <li><a onClick={(e) => updateToState(issue, e)} className="dropdown-item">Closed</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>

    );

    return (
        <div>
            <div className='row'>
                <div className='col-2 my-auto'>
                    <p className='light-iron legend'>Name</p>
                </div>
                <div className='col-4 my-auto ps-5 pe-5'>
                    <p className='light-iron legend'>Sentiment</p>
                </div>
                <div className='col my-auto text-center'>
                    <p className='light-iron legend'>Hits</p>
                </div>
                <div className='col my-auto text-center'>
                    <p className='light-iron legend'>Hits evolution</p>
                </div>
                <div className='col-auto my-auto text-end'>
                </div>
                <div className='col-auto my-auto text-end'>
                </div>
            </div>
            {distribution}
        </div>
    );
}

export default IssueComponent;