import React from 'react';

import '../styles/pills.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

function PillsList({ issue, keywords, show, handler }: any) {
    const className = 'col-auto pill';

    function removeKeyword(keyword: any) {
        handler(keyword, issue)
    }

    const distribution = keywords.map((keyword: any, index: number) =>
        <div className={className}>
            {
                show === true && <p className='ps-3 bg-indigo'>{keyword} <span className='ms-2'><button className='btn btn-sm' onClick={() => removeKeyword(keyword)}> <FontAwesomeIcon icon={faTrashCan} size="xs" color='#fff' /> </button></span></p>
            }
            {
                show === false || show === undefined && <p className='bg-apporange'>{keyword}</p>
            }
        </div>
    );

    return (
        <div className='row gx-1'>
            {distribution}
        </div>
    );
}

export default PillsList;