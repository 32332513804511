import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../resources/logo.svg'

import '../styles/navigation.scss';

function PublicNavigationHeader({ menu }: any) {
    return (
        <div>
            <nav className='navbar bg-white navbar-expand-lg'>
                <a href='/' className='navbar-brand'>
                    <img src={logo} height={40 + 'px'} alt='logo'></img>
                </a>
                {menu == true &&
                    <>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className='navbar-nav ms-auto'>
                                <li className='nav-item me-5'>
                                    <a className='nav-link' href='#about_section'>Features</a>
                                </li>
                                <li className='nav-item me-5'>
                                    <a className='nav-link' href='#pricing_section'>Pricing</a>
                                </li>
                                <li className='nav-item me-5'>
                                    <a className='nav-link' href='#contacts_section'>Contact us</a>
                                </li>

                                <li className='nav-item'>
                                    <Link to='/authenticate'><button className='btn btn-cta-primary'>Enter</button></Link>
                                </li>
                            </ul>
                        </div>
                    </>
                }
            </nav>
        </div>
    );
}

export default PublicNavigationHeader;