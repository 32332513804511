import React, { useState } from 'react';

import '../styles/sidemenu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SideMenuItem({icon, title, index, isActive, handler } : any) {
    const [active, setActive] = useState(isActive);

    const toggleClass = (event: any) => {
        event.preventDefault();

        setActive(!active);
        handler(index);
    };

    const className = isActive ? 'nav-link active' : 'nav-link';

    return(
        <div>
            <button onClick={ toggleClass } className={ className }>
                <span className='me-3'><FontAwesomeIcon icon={ icon }/></span>
                { title }
            </button>
        </div>
    );
}

export default SideMenuItem;