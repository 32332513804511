import React from 'react';

import '../styles/alert.scss';

function AlertComponent({ type, alert }: any) {

    const finalType = type === undefined ? 0 : type;
    const typeClass = finalType === 0 ? 'alert-danger' : 'alert-success';

    const finalClass = 'alert alert-dismissable fade show ' + typeClass;

    return (
        <div className={finalClass} role='alert'>
            <div className='row'>
                <div className='col my-auto'>
                    <p className='bold'>{alert}</p>
                </div>
                <div className='col-auto'>
                    <button type='button' className='btn-close btn-close-white' data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
    );
}

export default AlertComponent;