import React from 'react';

import '../styles/typography.scss';

import { ClientStatement } from '../Interfaces/ClientStatement';

function StatementComponent({ imageName, quote, author, authorImage, title }: ClientStatement) {

    return (
        <div className='containter text-center ps-5 pe-5'>
            <div className='ps-sm-5 pe-sm-5'>
                <div className='col-auto mb-4'>
                    <img height={60} src={imageName} />
                </div>
                <div className='col'>
                    <div className=''>
                        <h5 className='bold'>"{quote}"</h5>
                    </div>
                    <div className='mt-5'>
                        <img className='mb-3 feedback-avatar' src={authorImage} />
                        <p className='mb-1 legend bold iron'>{author}</p>
                        <p className='legend bold light-iron'>{title}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatementComponent;