import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AppBanner from './AppBanner';
import AppDataContainer from './AppDataContainer';
import PrivateNavigationHeader from './PrivateNavigationHeader';
import SideMenu from './SideMenu';

import revault_logo from '../resources/footer_logo.png'

function AppComponent() {
    const [activeComponent, setActiveComponent] = useState(0);

    let navigate = useNavigate();

    const location = useLocation() as any;

    const itemTapped = (index: any) => {
        loadComponent(index);
    }

    function loadComponent(id: any) {
        console.log('Component ID: ' + id);

        if (id === 11) {
            goBack();
        } else {
            setActiveComponent(id);
        }
    }

    const openSettings = (event: any) => {
        setActiveComponent(10);
    }

    function goBack() {
        navigate(-1);
    }

    return (
        <div>
            <PrivateNavigationHeader subscription={location.state.subscription} handler={openSettings} />
            {/* { location.state.subscription.plan === 'A' &&
                <div className='container-fluid'>
                    <div className='bg-doriean p-5 mb-3'>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8569173412197648" crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                        style={ { display:'block' } }
                        data-ad-client="ca-pub-8569173412197648"
                        data-ad-slot="9698655062"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    </div>
                </div>
            } */}
            <AppBanner app={location.state.app} subscription={location.state.subscription} />
            <div className='container-fluid' style={{ height: 100 + '%' }}>
                <div className='row'>
                    <div className='side-menu col-md-2 bg-iron'>
                        <SideMenu plan={location.state.subscription.plan} handler={itemTapped} />
                    </div>
                    <div className='col-md-10 bg-cloudie p-5 full'>
                        {
                            activeComponent !== 11 && <AppDataContainer component={activeComponent} app={location.state.app} subscription={location.state.subscription} handler={itemTapped} />
                        }

                    </div>
                </div>
            </div>
            {/* { location.state.subscription.plan === 'A' &&
                <div className='container-fluid'>
                    <div className='bg-doriean p-5 mt-3'>
                        CAMPAIGN SPACE
                    </div>
                </div>
            } */}
            <footer className='p-3 text-center'>
                <a className='no-decoration' href='https://www.appinion.pt' target={'blank'}>
                    <p className='legend light-iron'>© 2024 Appinion All Rights Reserved.</p>
                    <img className='ms-3' height={16} src={revault_logo} alt="logo"></img>
                </a>
            </footer>
        </div>
    );
}

export default AppComponent;