import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import TopKeywordsComponent from '../Dashboard/TopKeywordsComponent';
import ReviewsDistributionByIssue from './ReviewsDistributionByIssue';
import GroupPopUpComponent from './GroupPopUpComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faAdd, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'
import { faList, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import NotificationAlertComponent from '../Dashboard/NotificationAlertComponent';

const networking = require('../../Networking/API');

function KeywordsComponent({ subscription, app }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [issues, setIssues] = useState(null) as any[];
    const [currentStore, setCurrentStore] = useState('');
    const [currentState, setCurrentState] = useState('');
    const [currentVersion, setCurrentVersion] = useState('All');
    const [groupToAdd, setGroupToAdd] = useState(false);
    const [viewMode, setViewMode] = useState('global');

    var maxIssues = subscription.plan === 'A' ? 2 : 5;

    if (!reviews) {
        getData();
    } else if (!issues) {
        getMainIssues();
    }

    const getAllData = () => {
        getData();
        getMainIssues();
    }

    function updatePage() {
        setLoading(true);
        getAllData()
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function getMainIssues() {
        networking.issuesFor(app.id).then((issues: any) => {
            setIssues(issues);
            setLoading(false);
        }).catch((error: Error) => {
            console.log(error.stack);
            setError(error.message);
            setLoading(false);
        });
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    function filterByState(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentState(value)
    }

    function addNewGroup() {
        setGroupToAdd(true);
    }

    function toggleView() {
        setViewMode(viewMode == 'global' ? 'detail' : 'global')
    }

    const replySent = (event: any) => {
        updatePage();
    };

    var currentItems = reviews;
    var versions: any[] = [];

    if (issues) {
        reviews.sort(function (a: any, b: any) {
            return new Date(b.updated).getTime() - new Date(a.updated).getTime();
        });

        versions = [... new Set(reviews.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            return a.localeCompare(b, undefined, { numeric: true });
        });
        versions.unshift("All");

        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        } else if (currentStore === 'Proactive feedback') {
            currentItems = currentItems.filter((r: any) => r.store === 'profeed');
        }

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }
    }

    return (
        <div className='keywords'>
            {groupToAdd &&
                <GroupPopUpComponent app={app} title='Add new group' message='Add a new group' type='group' handler={replySent} />
            }
            <div className='bg-doriean filter-container p-2'>
                <div className='row'>
                    <div className='col-auto my-auto'>
                        <div className='dropdown mb-1'>
                            <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                                <span className='me-2'>{currentStore === '' ? 'All stores' : currentStore}</span>
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStore} /></span> All stores</a></li>
                                <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faAppStore} /></span> Appstore</a></li>
                                <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faGooglePlay} /></span> Playstore</a></li>
                                <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faMessage} /></span> Proactive feedback</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-auto my-auto'>
                        <div className='dropdown mb-1'                                          >
                            <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                                <span className="me-2"> {currentVersion === 'All' ? "All versions" : currentVersion} </span>
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                {
                                    versions.map((version: string) => {
                                        return (
                                            <li><a onClick={(e) => filterByVersion(e)} className='dropdown-item'>{version}</a></li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col my-auto'>
                        <div className='dropdown mb-1'>
                            <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                                <span className='me-2'>{currentState === '' ? 'All States' : currentState}</span>
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                <li><a onClick={(e) => filterByState(e)} className='dropdown-item'>All states</a></li>
                                <li><a onClick={(e) => filterByState(e)} className='dropdown-item'>New</a></li>
                                <li><a onClick={(e) => filterByState(e)} className='dropdown-item'>Follow</a></li>
                                <li><a onClick={(e) => filterByState(e)} className='dropdown-item'>Assigned</a></li>
                                <li><a onClick={(e) => filterByState(e)} className='dropdown-item'>Closed</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-auto my-auto'>
                        <div className='row'>
                            <div className='col'>
                                <a type='button' className='p-0 iron legend bold' onClick={toggleView}>
                                    {viewMode == 'global' ? 'Detailed view' : 'Global view'}
                                </a>
                            </div>
                        </div>
                    </div>
                    {(subscription.plan === 'AAA' || subscription.plan === 'U') &&
                        <div className='col-auto my-auto'>
                            <button onClick={(e) => addNewGroup()} data-bs-toggle="modal" data-bs-target="#groupModal" className='btn iron legend bold'><span className="me-2"><FontAwesomeIcon icon={faAdd} /></span>Add new group</button>
                        </div>
                    }
                    {(subscription.plan !== 'AAA' && subscription.plan !== 'U') && issues && issues.length < maxIssues &&
                        <div className='col-auto my-auto'>
                            <button onClick={(e) => addNewGroup()} data-bs-toggle="modal" data-bs-target="#groupModal" className='btn iron legend bold'><span className="me-2"><FontAwesomeIcon icon={faAdd} /></span>Add new group</button>
                        </div>
                    }
                </div>
            </div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col'>
                    <div>
                        {viewMode == 'global' &&
                            <div className='card mt-3'>
                                {issues && issues.length === 0 &&
                                    <EmptyMessageComponent
                                        image={''}
                                        title={'Oh no!! You don\'t have group created yet!.'}
                                        description={'You can create groups and associate keywords to see your reviews separated by topic.'} />
                                }

                                <TopKeywordsComponent app={app} store={currentStore} version={currentVersion} showDetails={true} state={currentState} />
                            </div>
                        }
                        {viewMode == 'detail' &&
                            <div className='mt-3'>
                                <ReviewsDistributionByIssue reviews={currentItems} issues={issues} handler={updatePage} />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default KeywordsComponent;