import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, useSearchParams } from "react-router-dom";

import '../styles/authentication.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faL } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import PublicNavigationHeader from '../components/PublicNavigationHeader';
import AlertComponent from '../components/AlertComponent';

import bg from '../resources/bg.png'

import { Link } from 'react-router-dom';

const networking = require('../Networking/API');
const cookies = new Cookies();

function AuthenticationComponent() {
    let navigate = useNavigate();

    const [token, setToken] = useState(cookies.get('subs_token'));
    const [parameters, setParameters] = useSearchParams();
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const sessionId = parameters.get('session_id');
    const newSubscription = cookies.get('subscription_id');

    if (sessionId) {
        networking.customer(sessionId).then((data: any) => {
            networking.updateState(newSubscription, sessionId, data.str_customer_id, data.str_subscription_id).then((data: any) => {
                cookies.set('subs_token', newSubscription)
                setSuccess('🎉 Great! Your subscription was created with success! Please check your e-mail for more details on how to start using Appinion.')
            }).catch((error: Error) => {
                cookies.remove('subs_token')
                setError(error.message);
            });

        }).catch((error: Error) => {
            cookies.remove('subs_token')
            setError(error.message);
        });

        parameters.delete('session_id')
        setParameters(parameters)
    }

    if (cookies.get('subs_token')) {
        login(cookies.get('subs_token'))
    }

    const handleChange = (event: any) => {
        setError('');
        setToken(event.target.value);
    }

    const handleCloseCookies = (event: any) => {
        setShowDisclaimer(false);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (token === '') {
            setError('Please, enter your token.');

            return;
        }

        login(token)
    }

    function login(token: String) {
        networking.authenticateSubscription(token).then((data: any) => {
            cookies.set('subs_token', token);

            if (data.status === 'active') {
                navigate('/apps-list', { state: data });
            } else {
                setError('Your subscription is not active. Please contact main@appinion.pt for more information.')
            }
        }).catch((error: Error) => {
            setError(error.message);
        });
    }

    const subscribed = (event: any) => {
        navigate({ pathname: '/authenticate' })
    };

    return (
        <div className=''>
            <div className='container mb-5 pb-5'>
                <PublicNavigationHeader menu={false} />
            </div>
            <div className='container mt-5 pt-5'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <p className='semi-bold iron'><span className='me-3'><FontAwesomeIcon icon={faChevronLeft} /></span>back to appinion.pt</p>
                </Link>
                <div className='row mt-3'>
                    <div className='col p-3'>
                        <div className='card p-5' style={{ width: 100 + '%' }}>
                            {success && <div className='p-2'> <AlertComponent alert={success} type={1} /> </div>}
                            <form onSubmit={handleSubmit}>
                                <h4 className='iron text-center semi-bold'>Welcome</h4>
                                <p className='light-iron text-center mt-3 mb-5'>Good to see you!</p>
                                <label className='iron semi-bold legend'>Token</label>
                                <input className='form-control' type="text" name="token" placeholder={cookies.get('subs_token') || ""} onChange={handleChange} id="inputToken"></input>
                                {error && <p className='bold legend bloodie mt-3'>{error}</p>}
                                <button className='btn white bold login-btn mt-5'>
                                    Validate
                                </button>
                                <div className='text-center mt-5'>
                                    <Link style={{ textDecoration: 'none' }} to={'/subscribe'}>
                                        <div className='mt-3'>
                                            <p className='text-center legend light-iron mb-5'>Not a client yet? <a href=''><span className='apporange underlined'>Register</span></a></p>
                                        </div>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-sm'>
                        <div className='section mt-3 p-5' style={{ height: 100 + "%" }}>
                            <div className='pe-5'>
                                <h5 className='heading pe-5 mb-4 white'>Ready to elevate the way you handle your App reviews and ratings?</h5>
                                <p className='white'>If you already have a token, just insert it on the form on the left. If you don\'t have a token, please register to get one.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDisclaimer &&
                <div className='cookies container-fluid'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <p className='semi-bold legend'>This website uses cookies to provide you with the best experience.</p>
                            </div>
                            <div className='col-auto'>
                                <button className='btn' onClick={handleCloseCookies}>
                                    <FontAwesomeIcon icon={faClose} color='#ffffff' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default AuthenticationComponent;