import React,{ useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');

function PopUpComponent( { issue, title, message, handler } : any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [kw, setKw] = useState('');

    const updateKw = (event: any) => {
        setError('');
        
        setKw(event.target.value);
    };

    const addKeywords = (event: any) => {
        if( kw.length == 0) {
            setError('Please, add some keywords.');

            return;
        }

        setLoading(true);
        networking.updateIssue(issue, kw).then((success: boolean) => {
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });

        handler(true);
    }

    return(
        <div>
            { error && <div> <AlertComponent alert={ error } /> </div>}
            { loading ?
                <LoadingSpinnerComponent/>
                :
            <div className="modal fade" id="keywordModal" tabIndex={-1} aria-labelledby="keywordModalLabel" aria-hidden="true">
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <div className='p-3 mb-3 bg-cloudie'>
                                <p className='m-0 p-0 light-iron semi-bold info'>{ title }</p>
                            </div>
                            <div className='form-group'>
                                <div>
                                    <div className='mt-3'>
                                        <p className='prple semi-bold info'>Insert yout keywords separated by ','</p>
                                    </div>
                                    <input onChange={ (event) => updateKw(event) } className="form-control" id="recipient-name"></input>
                                </div>
                            </div>
                            <div className='row ps-3 pe-3 mt-5'>
                                <button data-bs-dismiss='modal' type="button" onClick={ addKeywords } className="btn btn-primary">Add</button>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default PopUpComponent;