import { faStore, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'

export function timeSince(date: string) {
  var seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }

  return Math.floor(seconds) + " seconds ago";
}

export function iconFor(store: string) {
  switch (store) {
    case "playstore":
      return faGooglePlay;
    case "applestore":
      return faAppStore;
    case "profeed":
      return faMessage;
    default:
      return faStore;
  };
}

export function canAddMoreApps(plan: string, apps: number) {
  if (plan === 'A') {
    return apps === 0;
  }

  if (plan === 'AA') {
    return apps < 3;
  }

  if (plan === 'AAA') {
    return apps < 10;
  }

  return true
}

export function base64For(file: any) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    }

  });
}

export const blackList = ["crash", "bad", "useless", "ui", "trash", "biometric", "face Id", "touch Id",
  "error", "worst", "oops", "ux", "don't like", "pain", "crashes", "etransfer",
  "e-transfer", "bill", "tired", "fix", "doesn't work", "slow", "issue", "issues",
  "awful", "terrible", "problems"]

export const whiteList = ["good", "love", "fast"]