import React from 'react';
import { Link } from 'react-router-dom';

function InfoBanner({ title, description, action, subscription }: any) {

    return (
        <div className='bg-doriean p-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1 className='bold iron'>{ title }</h1>
                        <p className='semi-bold light-iron'>{ description }</p>
                    </div>
                    { action && subscription &&
                        <div className='apps col-auto my-auto'>
                            <Link to={ action } state={ { subscription } }>
                                <button className='btn btn-cta-primary'>Add new app</button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default InfoBanner;