import React from 'react';

import '../styles/typography.scss';
import SectionHeader from './SectionHeader';

function PartnersComponent({ logos }: any) {

    const logos_slider = logos.map((logo: any, index: BigInteger) =>
        <div className='col-sm-auto'>
            <img alt={logo} key={'id_' + index} className="" src={logo} height={60 + 'px'}></img>
        </div>
    );

    return (
        <div className='container-fluid text-center bg-cloudie p-5 mt-5'>
            <h5 className='iron mt-5'>They already trust and use appinion</h5>
            <div className='row gx-5 justify-content-sm-center p-5'>
                {logos_slider}
            </div>
        </div>
    );
}

export default PartnersComponent;