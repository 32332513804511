import React from 'react';

import '../styles/star-rating.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

function StarRatingComponent({ rating, size } : any) {
    const c = Math.round(rating);
    const l = 5 - c;

    const baseClass = "star bold mr-1 " + size
    const grayClass = "star-grayed bold mr-1 " + size

    return (
        <div>
            {
                [...Array(c)].map((star) => {        
                    return (         
                        <span className={ baseClass }><FontAwesomeIcon icon={faStar}/></span>
                    );
                })
            }
            {
                [...Array(l)].map((star) => {        
                    return (         
                        <span className={ grayClass }><FontAwesomeIcon icon={faStar}/></span>
                    );
                })
            }
        </div>
    );
}

export default StarRatingComponent;