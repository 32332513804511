import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Animate } from "react-simple-animate";
import { ClientStatement } from './Interfaces/ClientStatement';
import Cookies from 'universal-cookie';

import './App.css';
import './styles/section.scss'
import './styles/typography.scss'
import "animate.css/animate.min.css";

import footer_appinion from './resources/footer_logo.png'
import autocarros from './resources/logo_autocarros.svg'
import maclub from './resources/maclub_logo.svg'
import accm from './resources/logo_main.svg'

import PublicMainBanner from './components/PublicMainBanner';
import PublicNavigationHeader from './components/PublicNavigationHeader';
import PartnersComponent from './components/PartnersComponent';
import SectionHeader from './components/SectionHeader';
import FeatureComponent from './components/HighlightComponent';
import PricingComponent from './components/PricingComponent';
import SegmentedControl from './components/SegmentedControl';
import StatementComponent from './components/StatementComponent';
import ContactUsForm from './components/Contact Form/ContactForm';

const cookies = new Cookies();

function App() {
  const [selectedMode, setselectedMode] = useState("monthly");

  const feature_1_title = 'Apple & Google Play reviews';
  const feature_1_description = 'Support for all major app stores. New reviews are fetched in everyday.';

  const feature_2_title = 'Integrate on your workflow';
  const feature_2_description = 'Export your reviews to use on your favourite tools.';

  const feature_3_title = 'Better roadmap decisions';
  const feature_3_description = 'Data-driven decisions based on real feedback and sentiment of  users. Track how users respond to your improvements as soon as they launch!';
  const feature_4_title = 'Find bugs faster';
  const feature_4_description = 'Use your reviews as an early-warning system and make sure your app is meeting your user expectations.';
  const feature_5_title = 'Connect your teams';
  const feature_5_description = 'Give reviews and ratings the visibility they deserve in your company. All levels and departments can easily stay informed with custom reports.';
  const feature_6_title = 'Higher star ratings';
  const feature_6_description = 'Use less effort to better understand your users.';

  const feature_7_title = 'Sentiment Analysis';
  const feature_7_description = 'Sentiment analysis model trained with thousands of App reviews and powered with best in class machine learning techniques.';
  const feature_8_title = 'Review analysis that save your team hours';
  const feature_8_description = 'Group your reviews in Topics and use Tags to keep them under your radar.';
  const feature_9_title = 'Access app reviews with our API';
  const feature_9_description = 'Build your own reports or integrate with a system we tou already use on your daily work.';
  const feature_10_title = 'Get feedback right from your App';
  const feature_10_description = 'Use our proactive feedback SDKs for iOS and Android to get feedback right from your App. With easy integration get to know how your users feel as soon as they make contact with new features.';
  const feature_11_title = 'Radar, Pathfinder and Snapshot!';
  const feature_11_description = 'Use the latest LLMs to go ever further. Clearly identify what your users like more and what their pains are. Draft your next steps and know exactly who your customer is.';

  const statement1 = {} as ClientStatement;
  statement1.quote = 'Appinion has been a game-changer for our app. The depth of insights provided is unparalleled, and the proactive approach through the SDK has allowed us to address user concerns before they become widespread. Appinion has truly elevated our app\'s user experience, and we couldn\'t be happier with the results!.';
  statement1.author = 'Thiago Garcia';
  statement1.title = 'Owner @ Autocarros Porto';

  cookies.remove("toplikes");
  cookies.remove("topdislikes");
  cookies.remove("roadmap");
  cookies.remove("persona");

  return (
    <div>
      <div id="header">
        <div className='container'>
          <PublicNavigationHeader menu={true} />
        </div>
        <Animate
          play
          start={{ opacity: 0 }}
          end={{ opacity: 1 }}
          duration={1}>
          <PublicMainBanner />
        </Animate>
      </div>

      <Animate
        play
        start={{ opacity: 0 }}
        end={{ opacity: 1 }}
        duration={1}
        delay={0.33}>
        <div className='mb-5'>
          <PartnersComponent logos={[autocarros, maclub, accm]} />
        </div>
      </Animate>

      <div id="about_section" className='p-3'>
        <div className='container pb-5'>
          <div className='container text-center mt-5 mb-5 p-5'>
            <h2 className="iron black">Monitor reviews and ratings</h2>
            <p className="light-iron tagline">Save your team precious hours by automating manual processes.</p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-4'>
              <div className='col-md'>
                <FeatureComponent icon={''}
                  title={feature_1_title}
                  subtitle={feature_1_description} />
              </div>
              <div className='col-md'>
                <FeatureComponent icon={''}
                  title={feature_2_title}
                  subtitle={feature_2_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='section-colored p-5 text-center'>
              <Link to={"/subscribe"}>
                <a href="#cta_section" className="btn btn-light">Start now</a>
              </Link>
            </div>
          </AnimationOnScroll>
          <div className='container text-center mt-5 mb-5 p-5'>
            <h2 className="iron black">Improve customer satisfaction</h2>
            <p className="light-iron tagline">Make better and faster decisions.</p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-3'>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_3_title} subtitle={feature_3_description} />
              </div>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_4_title} subtitle={feature_4_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-3'>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_5_title} subtitle={feature_5_description} />
              </div>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_6_title} subtitle={feature_6_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='section-colored p-5 text-center'>
              <Link to={"/subscribe"}>
                <a href="#cta_section" className="btn btn-light">Start now</a>
              </Link>
            </div>
          </AnimationOnScroll>
          <div className='container text-center mt-5 mb-5 p-5'>
            <h2 className="iron black">Deep analysis</h2>
            <p className="light-iron tagline">Dig deep on what users feel about your App.</p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-3'>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_7_title} subtitle={feature_7_description} />
              </div>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_8_title} subtitle={feature_8_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-3'>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_9_title} subtitle={feature_9_description} />
              </div>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_10_title} subtitle={feature_10_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='row gx-3 gy-2 mt-3'>
              <div className='col'>
                <FeatureComponent icon={''} title={feature_11_title} subtitle={feature_11_description} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce={true}
            duration={0.33}>
            <div className='section-colored p-5 text-center'>
              <Link to={"/subscribe"}>
                <a href="#cta_section" className="btn btn-light">Start now</a>
              </Link>
            </div>
          </AnimationOnScroll>
        </div>
      </div>

      <div id='mentions' className='container mb-5'>
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOnce={true}
          duration={0.33}>
          <div className='row gy-5'>
            <div className=' mx-auto col'>
              <StatementComponent
                imageName={autocarros}
                quote={statement1.quote}
                authorImage='https://res.cloudinary.com/dwggfdgyw/image/upload/v1718904626/thga_slytz9.jpg'
                author={statement1.author}
                title={statement1.title}
              />
            </div>
          </div>
        </AnimationOnScroll>
      </div>

      {/* <div className=''>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-lg my-auto p-5'>
              <h1 className='apporange bold text-center mt-5'>Proactive feedback</h1>
              <SectionHeader title={''} subtitle={''} other={'Elevate your mobile app experience with our seamless iOS, Android SDKs, providing real-time feedback analysis and personalized insights to enhance user engagement and drive business growth.'} />
            </div>
            <div className='col-lg my-auto p-5 text-center'>
              <img src={profeedback} alt="preview" width="50%"></img>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg my-auto p-5 text-center'>
              <img src={ai_image} alt="preview" width="75%"></img>
            </div>
            <div className='col-lg my-auto p-5'>
              <h1 className='apporange bold text-center mt-5'>AI powered sentiment analysis</h1>
              <SectionHeader title={''} subtitle={''} other={'Appinion uses AI models to generate analysis that reflect how users are reacting to your App. We use deep learning to fine-tune our models and ensure a high degree of accuracy.'} />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg my-auto p-5'>
              <h1 className='apporange bold text-center mt-5'>Topic modeling</h1>
              <SectionHeader title={''} subtitle={''} other={'Whether you\'re looking to optimize your marketing campaigns, improve customer satisfaction, or identify new product opportunities, topic modeling is the tool you need to unlock the full potential of your feedback data.'} />
            </div>
            <div className='col-lg my-auto p-5 text-center'>
              <img src={modeling} alt="preview" width="75%"></img>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='mt-5'>
        <div className='container text-center'>
          <img src={mockup} alt="preview" width="100%"></img>
        </div>
      </div> */}

      <div id="pricing_section" className='container-fluid bg-doriean pb-5'>
        <SectionHeader
          title={'Pricing'}
          subtitle={''}
          other='It is always better when you have a plan...'
        />
        <div className='pb-3'>
          <SegmentedControl
            name="group-1"
            callback={(val: any) => setselectedMode(val)}
            controlRef={useRef<boolean>(true)}
            segments={
              [
                {
                  label: "Monthly",
                  value: "monthly",
                  ref: useRef<boolean>(true)
                },
                {
                  label: "Yearly",
                  value: "yearly",
                  ref: useRef<boolean>(true)
                }
              ]
            }
          />
        </div>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg'>
              <PricingComponent
                plan={'Free'}
                mode={selectedMode}
                description={'All the essential features, making it ideal for individual\
                   users or small-scale projects.'}
                features={
                  [
                    "1 App",
                    "Reviews from Appstore and Playstore",
                    "Heart beat",
                    "2 topics on Keywords",
                    "Last month report"
                  ]
                }
                price={"0"}
                buttonTitle={'Try now!'}
                hide={false} />
            </div>
            <div className='col-lg'>
              <PricingComponent
                plan={'Indie'}
                mode={selectedMode}
                description={'Affordable and feature-rich, it\'s the next step for those looking to elevate their projects.'}
                features={
                  [
                    "up to 3 Apps",
                    "Reviews from Appstore and Playstore",
                    "Heart beat",
                    "5 topics on Keywords",
                    "3 months report",
                    "Radar"
                  ]
                }
                price={selectedMode === 'monthly' ? "19.90" : "199"}
                hide={false}
                buttonTitle={'Try now!'}
                featured={true} />
            </div>
            <div className='col-lg'>
              <PricingComponent
                plan={'Business'}
                mode={selectedMode}
                description={'Premium features for collaboration and analytics. Perfect for multiple app developers.'}
                features={
                  [
                    "up to 10 Apps",
                    "Reviews from Appstore and Playstore",
                    "Heart beat",
                    "Unlimited topics on Keywords",
                    "6 months report",
                    "Radar",
                    "Pathfinder",
                    "Snapshot"
                  ]
                }
                price={selectedMode === 'monthly' ? "49.90" : "499"}
                hide={false}
                buttonTitle={'Try now!'}
                featured={false} />
            </div>
            <div className='col-lg'>

              <PricingComponent
                plan={''}
                description={
                  'Enjoy unlimited app management, along with premium tools. \
                This plan is designed to meet the specific needs of your business. \
                Contact us to discuss a personalized plan that aligns perfectly with your goals and needs.'
                }
                features={[]}
                hide={true} />
            </div>
          </div>
        </div>
      </div>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animateOnce={true}
        duration={0.33}>
        <div className='ps-3 pe-3'>
          <div id='cta_section' className='section container mt-5 pb-5'>
            <div className='row'>
              <div className='col-md my-auto'>
                <SectionHeader
                  title={'Ready for some feedback?'}
                  subtitle={
                    'Ask for more info or request a demo to see appinion in action.'
                  }
                  light={true}
                />
              </div>
              <div className='col-md my-auto p-sm-5'>
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <div id='contacts_section' className='p-5 text-center'>
        <div className='mb-5'>
          <p className='iron bold legend light-iron'>or send us an email</p>
          <a style={{ textDecoration: 'none' }} href="mailto:main@appinion.pt?subject=Mail from Our Site">
            <span className="iron bold legend">main@appinion.pt</span>
          </a>
        </div>
        <div className='row justify-content-center'>

          <div className='col-auto'>
            <Link to='/docs#welcome' style={{ textDecoration: 'none' }}>
              <a style={{ textDecoration: 'none' }} className='iron bold legend'>Documents</a>
            </Link>
          </div>
          <div className='col-auto'>
            <Link to='/terms' style={{ textDecoration: 'none' }}>
              <a style={{ textDecoration: 'none' }} className='iron bold legend'>Terms and Conditions</a>
            </Link>
          </div>
          <div className='col-auto'>
            <Link to='/privacy' style={{ textDecoration: 'none' }}>
              <a style={{ textDecoration: 'none' }} className='iron bold legend'>Privacy Policy</a>
            </Link>
          </div>
        </div>
      </div>

      <footer className='p-3 text-center'>
        <p className='legend light-iron'>© Appinion 2024. All rights reserved</p>
        <img className='ms-3' src={footer_appinion} alt="logo" style={{ height: 16 + 'px' }}></img>
        <ul className='navbar-nav ms-auto'>
          <li className='nav-item'>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
