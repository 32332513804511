import React, { useState } from 'react';

import PredictTopComponent from './PredictorTopComponent';
import PredictRoadmapComponent from './PredictorRoadmapComponent';
import PredictorPersonaGenerator from './PredictorPersonaGenerator';

const networking = require('../../Networking/API');

function PredictorComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    return (
        <div>
            <h3 className='iron bold'>
                The Radar
            </h3>
            <p className='light-iron legend'>
                Based on the latest <b>50</b> reviews of your App, the <b>Radar</b> tool will list the most liked and unliked features of your App.
                Just hit the <b>Get</b> button.
            </p>
            <div className='row gy-3 gx-3 mt-3' >
                <div className='col'>
                    <div className='card bg-doriean' style={{ height: 100 + '%' }}>
                        <PredictTopComponent app={app.app} type={'toplikes'} />
                    </div>
                </div>
                <div className='col'>
                    <div className='card bg-doriean' style={{ height: 100 + '%' }}>
                        <PredictTopComponent app={app.app} type={'topdislikes'} />
                    </div>
                </div>
            </div>
        </div>

    );

}

export default PredictorComponent;