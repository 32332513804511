import React from 'react';
import AppInfoOverviewComponent from './AppInfoOverviewComponent';
import NotificationAlertComponent from './NotificationAlertComponent';
import SentimentBreakdownComponent from './SentimentBreakdownComponent';
import SentimentGaugeComponent from './SentimentGaugeComponent';
import TopKeywordsComponent from './TopKeywordsComponent';
import AppRatingComponent from './AppRatingComponent';

function DashboardComponent(app: any) {
    return (
        <div className=''>
            <AppRatingComponent app={app.app} type={'apple'} />
            <AppRatingComponent app={app.app} type={'google'} />
            <AppInfoOverviewComponent app={app.app} />
            <div className='p-0 mt-3'>
                <div className='row gy-3 gx-3' >
                    <div className='col-lg-3'>
                        <NotificationAlertComponent app={app.app} />
                    </div>
                    <div className='col-lg-4'>
                        <div className='card' style={{ height: 100 + '%' }}>
                            <SentimentGaugeComponent app={app.app} />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='card' style={{ height: 100 + '%' }}>
                            <SentimentBreakdownComponent app={app.app} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-0 mt-3'>
                <div className='card'>
                    <h1 className='light-iron legend semi-bold mb-5'>Top Keywords</h1>
                    <TopKeywordsComponent app={app.app} limit={5} state={''} />
                </div>
            </div>
        </div>
    )
}

export default DashboardComponent;