import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');

function ReplyPopUpComponent({ app, review, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [reply, setReply] = useState('');

    const updateReply = (event: any) => {
        setError('');

        setReply(event.target.value);
    };

    const sendReply = (event: any) => {
        setError('');
        if (reply === '') {
            return;
        }

        setLoading(true);
        if (review.store === 'playstore') {
            networking.sendPlaystoreReply(app.app.id, review.store_id, reply).then((success: boolean) => {
                setLoading(false);
                handler(true);
            }).catch((error: Error) => {
                setError(error.message);
                setLoading(false);
            });
        } else {
            networking.sendAppstoreReply(app.app.id, review.store_id, reply).then((success: boolean) => {
                setLoading(false);
                handler(true);
            }).catch((error: Error) => {
                setError(error.message);
                setLoading(false);
            });
        }
    };

    return (
        <div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className="modal fade" id="replyModal" tabIndex={-1} aria-labelledby="replyModalLabel" aria-hidden="true">
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-body'>
                                <div className='p-3 mb-3 bg-cloudie'>
                                    <p className='m-0 p-0 light-iron semi-bold info'>{review.content}</p>
                                </div>
                                <div className='form-group'>
                                    <div className='mt-3'>
                                        <p className='prple semi-bold info'>Reply below</p>
                                    </div>
                                    <textarea onChange={(event) => updateReply(event)} className="form-control" id="recipient-name"></textarea>
                                </div>
                                <div className='row ps-3 pe-3 mt-5'>
                                    <button data-bs-dismiss='modal' type="button" onClick={sendReply} className="btn btn-primary">Reply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ReplyPopUpComponent;