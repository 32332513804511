import React from 'react';

function LoadingSpinnerComponent(white: any) {
    var className = white === true ? 'spinner-border white' : 'spinner-border light-iron'

    return (
        <div className='d-flex justify-content-center p-5'>
            <div className={className}></div>
        </div>
    );
}

export default LoadingSpinnerComponent;