import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/sentiment-gauge.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThunderstorm } from '@fortawesome/free-solid-svg-icons';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { faSun } from '@fortawesome/free-solid-svg-icons';

const networking = require('../../Networking/API');

function SentimentGaugeComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    var color = '';
    var overlay = '';
    var state = '';
    var icon = faCloud;
    var rotation;

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    if (reviews) {
        const badReviews = reviews.filter((d: any) => d.sentiment === 'bad').length;
        const goodReviews = reviews.filter((d: any) => d.sentiment === 'good').length;
        const neutralReviews = reviews.filter((d: any) => d.sentiment === ' neutral').length;

        const total = reviews.length;

        const badPercentage = badReviews / total;
        const goodPercentage = goodReviews / total;
        const neutraPercentage = neutralReviews / total;

        rotation = neutraPercentage * 90 + goodPercentage * 147 + badPercentage * 33;

        //Corresponds to a bad sentiment classification
        if (rotation < 60) {
            color = '#BD0000';
            overlay = '#BD0000';
            state = 'After analysing ' + total + ' reviews, your sentiment score is: ' + Math.round(rotation) +
                '. This means that your users reviews are consistently bad and you should do something.';
            icon = faThunderstorm;
        }

        //Corresponds to a neutral sentiment classification
        if (rotation > 60 && rotation < 147) {
            color = '#8C8CA1';
            overlay = '#EAD200';
            state = 'After analysing ' + total + ' reviews, your sentiment score is: ' + Math.round(rotation) +
                '. This means that you should pay more attention to your users reviews.';
            icon = faCloud;
        }

        //Corresponds to a good sentiment classification
        if (rotation > 147) {
            color = '#EAD200';
            overlay = '#b1bd65';
            state = 'After analysing ' + total + ' reviews, your sentiment score is: ' + Math.round(rotation) +
                '. This means that your users like your app consistently. Keep up the good work.';
            icon = faSun;
        }


        if (rotation > 180) {
            rotation = 180;
        }
    }

    const style = {
        width: 200 + 'px',
        ['--rotation']: rotation + 'deg',
        ['--color']: overlay,
        ['--background']: '#e9ecef'
    } as any;

    return (
        <div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <div className='text-center'>
                        <h1 className='light-iron legend semi-bold'>SENTIMENT GAUGE</h1>
                        <div className='gauge mx-auto mt-5' style={style}>
                            <div className='percentage '></div>
                            <div className="mask">
                                <div className='mt-5'>
                                    <FontAwesomeIcon icon={icon} size='2x' color={color} />
                                </div>
                            </div>
                        </div>
                        <p className='light-iron semi-bold legend mt-5'>{state}</p>
                    </div>
                </div>
            }
        </div >
    )
}

export default SentimentGaugeComponent;