import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AuthenticationComponent from './components/AuthenticationComponent';
import AppsListComponent from './components/Apps List/AppsListComponent';
import AppComponent from './components/AppComponent';
import ManageAppComponent from './components/Apps List/ManageAppComponent';
import ManageStageComponent from './components/Apps List/ManageAppComponent';
import DocumentationMainComponent from './components/Documentation/DocumentationMainComponent';
import ClientManagementPage from './components/Client Management/ClientManagementPage';
import Terms from './components/Terms';
import Privacy from './components/Privacy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}></Route>
        <Route path='/authenticate' element={<AuthenticationComponent />}></Route>
        <Route path='/subscribe' element={<ClientManagementPage />}></Route>
        <Route path='/apps-list' element={<AppsListComponent />}></Route>
        <Route path='/app/:id' element={<AppComponent />}></Route>
        <Route path='/app/:id/manage' element={<ManageAppComponent />}></Route>
        <Route path='/manage/app' element={<ManageAppComponent />}></Route>
        <Route path='/manage/stage' element={<ManageStageComponent />}></Route>
        <Route path='/docs' element={<DocumentationMainComponent />}></Route>
        <Route path='/terms' element={<Terms />}></Route>
        <Route path='/privacy' element={<Privacy />}></Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
