import React from 'react';

import '../styles/typography.scss';
import '../App.css'

function FeatureComponent({ icon, title, subtitle }: any) {
    return (
        <div className='feature text-center bg-white shadow-lg rounded-4 p-5'>
            {icon &&
                <div className=''>
                    <img src={icon} alt='feature-icon'></img>
                </div>
            }
            <div className=''>
                <h3 className='iron feature-title mt-3'>{title}</h3>
                <p className='light-iron h6 mt-4'>{subtitle}</p>
            </div>
        </div>
    );
}

export default FeatureComponent;