import React,{ useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');

function PopUpComponent( { app, title, message, type, handler } : any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [text, setText] = useState('');
    const [kw, setKw] = useState('');

    const updateText = (event: any) => {
        setError('');
        
        setText(event.target.value);
    };

    const updateKw = (event: any) => {
        setError('');
        
        setKw(event.target.value);
    };

    
    const addGroup = (event: any) => {
        if( text.length == 0) {
            setError('Please, choose a name for the group.');

            return;
        }

        if( kw.length == 0) {
            setError('Please, add some keywords.');

            return;
        }

        setLoading(true);
        networking.addIssue(app, text, kw).then((success: boolean) => {
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });

        handler(true);
    }

    return(
        <div>
            { error && <div> <AlertComponent alert={ error } /> </div>}
            { loading ?
                <LoadingSpinnerComponent/>
                :
            <div className="modal fade" id="groupModal" tabIndex={-1} aria-labelledby="groupModalLabel" aria-hidden="true">
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <div className='p-3 mb-3 bg-cloudie'>
                                <p className='m-0 p-0 light-iron semi-bold info'>{ title }</p>
                            </div>
                            <div className='form-group'>
                                <div>
                                    <div className='mt-3'>
                                        <p className='prple semi-bold info'>Insert group name</p>
                                    </div>
                                    <input onChange={ (event) => updateText(event) } className="form-control" id="recipient-name"></input>
                                    </div>
                                <div>
                                    <div className='mt-3'>
                                        <p className='prple semi-bold info'>Insert yout keywords separated by ','</p>
                                    </div>
                                    <input onChange={ (event) => updateKw(event) } className="form-control" id="recipient-name"></input>
                                </div>
                            </div>
                            <div className='row ps-3 pe-3 mt-5'>
                                <button data-bs-dismiss='modal' type="button" onClick={ addGroup } className="btn btn-primary">Add</button>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default PopUpComponent;