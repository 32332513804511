import React, { useState } from 'react';
import csvDownload from 'json-to-csv-export'

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import ReviewComponent from './ReviewComponent';
import ReplyPopUpComponent from './ReplyPopUpComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStore, faDownload, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'

import '../../styles/dropdowns.scss';

import PaginationComponent from '../PaginationComponent';

const networking = require('../../Networking/API');

function ReviewsComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStore, setCurrentStore] = useState('');
    const [currentRating, setCurrentRating] = useState(5);
    const [currentVersion, setCurrentVersion] = useState('All');
    const [reviewToReply, setReviewToReply] = useState<Review>();

    const itemsPerPage = 20;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFisrtItem = indexOfLastItem - itemsPerPage;
    var versions: any[] = [];

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function paginate(page: number) {
        setCurrentPage(page)
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    function filterByRating(e: any, index: number) {
        e.preventDefault();

        setCurrentRating(index)
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
    }

    function downloadCSV() {
        const data = {
            data: currentItems,
            filemame: "reviews.csv"
        }

        csvDownload(data)
    }

    const reviewTapped = (review: Review) => {
        setReviewToReply(review);
    }

    const replySent = (event: any) => {
        getData();
    };

    let list = null;

    if (reviews) {
        reviews.sort(function (a: any, b: any) {
            return new Date(b.updated).getTime() - new Date(a.updated).getTime();
        });

        versions = [... new Set(reviews.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            return a.localeCompare(b, undefined, { numeric: true });
        });
        versions.unshift("All");

        var currentItems = reviews;

        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        } else if (currentStore === 'Proactive feedback') {
            currentItems = currentItems.filter((r: any) => r.store === 'profeed');
        }

        currentItems = currentItems.filter((r: any) => r.rating <= currentRating);

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }

        var parsedReviews = currentItems.slice(indexOfFisrtItem, indexOfLastItem);

        list = parsedReviews.map((review: Review, index: number) =>
            <div className='mb-3'>
                <ReviewComponent
                    review={review}
                    canReply={review.store === 'applestore' ? app.app.appstore_connect : app.app.playstore_connect}
                    handler={reviewTapped} />
            </div>
        )
    }

    return (
        <div className='reviews-list'>
            {reviewToReply &&
                <ReplyPopUpComponent
                    app={app}
                    review={reviewToReply}
                    handler={replySent} />
            }
            <div className='row p-2 bg-doriean mb-4 filter-container'>
                <div className='col-auto my-auto'>
                    <div className='dropdown mb-1'>
                        <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                            <span className='me-2'>{currentStore === '' ? 'All stores' : currentStore}</span>
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStore} /></span> All stores</a></li>
                            <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faAppStore} /></span> Appstore</a></li>
                            <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faGooglePlay} /></span> Playstore</a></li>
                            <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faMessage} /></span> Proactive feedback</a></li>
                        </ul>
                    </div>
                </div>
                <div className='col-auto my-auto'>
                    <div className='dropdown mb-1'                                          >
                        <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                            <span className="me-2"> {currentVersion === 'All' ? "All versions" : currentVersion} </span>
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            {
                                versions.map((version: string) => {
                                    return (
                                        <li>
                                            <a
                                                onClick={(e) => filterByVersion(e)}
                                                className='dropdown-item'>{version}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='col my-auto'>
                    <div className='dropdown mb-1'>
                        <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                            <span className="me-2">{
                                [...Array(currentRating)].map((star) => {
                                    return (
                                        <span><FontAwesomeIcon icon={faStar} color='#EAD200' /></span>
                                    );
                                })
                            }
                            </span>
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            <li><a onClick={(e) => filterByRating(e, 1)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStar} color='#EAD200' /></span></a></li>
                            <li><a onClick={(e) => filterByRating(e, 2)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /></span></a></li>
                            <li><a onClick={(e) => filterByRating(e, 3)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /></span></a></li>
                            <li><a onClick={(e) => filterByRating(e, 4)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /></span></a></li>
                            <li><a onClick={(e) => filterByRating(e, 5)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /> <FontAwesomeIcon icon={faStar} color='#EAD200' /></span></a></li>
                        </ul>
                    </div>
                </div>
                <div className='col-auto my-auto'>
                    <div className='action'>
                        <button onClick={() => downloadCSV()} type="button" className="btn btn-sm">
                            <span className="me-2">
                                <FontAwesomeIcon icon={faDownload} />
                            </span>Download as CSV
                        </button>
                    </div>
                </div>
            </div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col-lg'>
                    <div className='card reviews-card'>
                        {parsedReviews && parsedReviews.length == 0 &&
                            <EmptyMessageComponent
                                image={''}
                                title={'No reviews found.'}
                                description={'We\'ve not found any reviews. Please try another search criteria.'} />
                        }
                        {list}
                        <PaginationComponent
                            itemsPerPage={itemsPerPage}
                            totalItems={currentItems.length}
                            paginate={paginate}
                            currentPage={currentPage} />
                    </div>
                </div>
            }
        </div>
    )
}

export default ReviewsComponent;