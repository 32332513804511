import React from 'react';
import PillsList from '../PillsListComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'

import StarRatingComponent from '../StarRatingComponent';

import '../../styles/reviews.scss';

const helper = require('../../Helpers/helpers');

function ReviewComponent({ review, canReply, handler }: any) {

    let sentimentClass = 'sentiment-box ' + review.sentiment;

    const replyTapped = (review: Review) => {
        handler(review);
    };

    return (
        <div className='mb-1 pb-2'>
            <div className="row mt-2 review-item border-bottom">
                <div className="col-md-2 me-3 border-end">
                    <div className='mb-4'><StarRatingComponent rating={review.rating} size={"small"} /></div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faUser} /></span>{review.author}</div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faCodeBranch} /></span>{review.version}</div>
                    <div className="review-list-info"><span className="me-3"><FontAwesomeIcon icon={helper.iconFor(review.store)} /></span></div>
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className="ml-0">
                            <div className='row gy-3'>
                                <div className='col-auto'>
                                    <div className={sentimentClass}></div>
                                </div>
                                <div className='col-auto'>
                                    <h5 className="card-title title"> {review.title}<span className="ms-2 review-list-date">{helper.timeSince(review.updated)}</span></h5>
                                </div>
                            </div>
                            <p className="content mt-3">{review.content}</p>
                            <p style={{ display: review.reply === "" || review.reply === null ? "none" : "block" }} className="card-text review-list-info">Reply: {review.reply}</p>
                            <div className='mt-2'>
                                <PillsList keywords={review.tokens} />
                            </div>
                        </div>
                    </div>
                </div>
                {canReply === true &&
                    <div className='col-auto'>
                        <div className='row'>
                            <div className='col'>
                                <button onClick={(e) => replyTapped(review)}
                                    style={{ display: (review.reply === "" || review.reply === null) ? "block" : "none" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#replyModal"
                                    className='btn btn-outline-dark btn-sm'>
                                    <span className="me-2"><FontAwesomeIcon
                                        icon={faCommentDots} />
                                    </span>
                                    Reply
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ReviewComponent;