import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie';

import logo from '../resources/logo.svg'
import placeholder from '../resources/placeholder_icon.svg'

import '../styles/navigation.scss';
import '../styles/typography.scss';
import '../styles/colors.scss';

function PrivateNavigationHeader({ subscription, handler }: any) {
    const cookies = new Cookies();

    function clearCookies() {
        cookies.remove("subs_token");
    }

    const openCustomerSettings = (event: any) => {
        handler(event)
    }

    return (
        <div>
            <nav className='navbar bg-white navbar-expand-lg'>
                <a href='/' className='navbar-brand'>
                    <img src={logo} height={40 + 'px'} alt='logo'></img>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className='navbar-nav ms-auto'>
                        <li className='nav-item'>
                            <div className='row user-container p-3 bg-cloudie '>
                                <div className='col text-end'>
                                    <p className='semi-bold iron mb-0'>Hello, {subscription.name}</p>
                                    <p className='legend light-iron mb-0'>{subscription.businessname}</p>
                                </div>
                                <div className='col-auto my-auto'>
                                    {subscription.profileimage ? <img className="profile" height={40} src={subscription.profileimage}></img> : <img className="profile" height={40} src={placeholder}></img>}
                                </div>
                                <div className='col-auto my-auto'>
                                    <button className='btn' onClick={openCustomerSettings}><FontAwesomeIcon icon={faGear} /></button>
                                </div>
                            </div>
                        </li>
                        <li className='nav-item my-auto ms-3'>
                            <a onClick={clearCookies}>
                                <Link to='/authenticate'><button className='btn btn-cta-primary-dark'>Leave</button></Link>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default PrivateNavigationHeader;