import React from 'react';

import '../styles/star-rating.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

function RatingDistributionComponent({ reviews, total }: any) {

    const distribution = reviews.map((list: any, index: number) =>
        <div className='row my-auto'>
            <div className="col-auto text-left">
                <span className="legend light-iron">{index + 1} &#9733;</span>
            </div>
            <div className='col-9 p-0 my-auto'>
                <div className='bg-doriean' style={{ width:100 +'%', height:8 + 'px', borderRadius:  5 + 'px' }}>
                    <div className='bg-peachie' style={{ width:(list.length / total) * 100 +'%', height:8 + 'px', borderRadius: 5 + 'px'}}></div>
                </div>
            </div>
            <div className="col my-auto iron hint semi-bold">{list.length}</div>
        </div>
    );

    return (
        <div>
            { distribution }
        </div>
    );
}

export default RatingDistributionComponent;