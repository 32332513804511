import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import '../styles/topbanner.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faAppStore } from '@fortawesome/free-brands-svg-icons'

function AppBanner(app: any, subscription: any) {
    let navigate = useNavigate();

    const stores = () => {
        if (app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faAppStore} /> + <FontAwesomeIcon icon={faGooglePlay} /></span>
        } else if (app.app.androidpackageId && !app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faGooglePlay} /></span>
        } else if (!app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faAppStore} /></span>
        }
    }

    const goBack = (event: any) => {
        navigate(-1);
    }

    return (
        <div className='bg-doriean app-banner'>
            <div className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col-auto p-0'>
                        <img className='app-icon' src={app.app.icon} height={48}></img>
                    </div>
                    <div className='col'>
                        <p className='semi-bold iron mb-0'>{app.app.name}</p>
                        <p className='light-iron info mb-0'>{app.app.country} | {stores()}</p>
                    </div>
                    <div className='col-auto bg'>
                        <div className='row gx-2'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppBanner;