import React from 'react';
import PublicNavigationHeader from './PublicNavigationHeader';

import footer_appinion from '../resources/footer_logo.png'

function Terms() {

    return (
        <div>
            <PublicNavigationHeader menu={false} />
            <div className='container col-6'>

                <h1 className='iron semi-bold mt-5'>Appinion Terms and Conditions</h1>
                <h5 className='iron mt-5 mb-4'>1. Acceptance of Terms</h5>
                <p>By using the Appinion service ("Service"), you confirm that you are over 18 years old and agree to be bound by these Terms and Conditions.</p>
                <h5 className='iron mt-5 mb-4'>2. Description of Service</h5>
                <p>Appinion offers a qualitative analysis platform for app reviews, empowering users to extract valuable insights for app improvement. The Service is divided into Free and Paid Plans, and registration is required for use.</p>
                <h5 className='iron mt-5 mb-4'>3. User Accounts</h5>
                <p><span className='bold'>3.1. </span>Users must be over 18 years old and provide accurate and complete registration information.</p>
                <p><span className='bold'>3.2. </span>Users are responsible for maintaining the confidentiality of their account information.</p>
                <p><span className='bold'>3.3. </span>Appinion reserves the right to suspend or terminate accounts at its discretion.</p>
                <h5 className='iron mt-5 mb-4'>4. Subscription Plans</h5>
                <p><span className='bold'>4.1. </span>The Service includes Free and Paid Plans. Details regarding pricing, payment terms, and subscription plans are available on the Appinion website.</p>
                <p><span className='bold'>4.2. </span>The right to change pricing or modify plans with notice.</p>
                <h5 className='iron mt-5 mb-4'>5. User Conduct</h5>
                <p><span className='bold'>5.1. </span>Users must be over 18 years old and comply with all applicable laws.</p>
                <p><span className='bold'>5.2. </span>Users must agree that it shall only use the Appinion Service for legal purposes and shall not use it to engage any conduct that is unlawful,
                    immoral, threatening, abusive or in a way that is deemed unreasonable by Appinion in its discretion.
                    Appinion may terminate a User's access to the Service if considers that the User is in breach of this clause.</p>
                <p><span className='bold'>5.3. </span>Users may not in any circumstances resell, licence or otherwise commercialise any App Data or App Store Reviews.
                    The Service is provided for a User's internal use only.
                    Appinion may terminate a User's access to the Service if considers that the User is in breach of this clause.</p>
                <p><span className='bold'>5.4. </span>Appinion data may not be published publicly (either online or offline) without the prior express written consent of Appinion.</p>
                <p><span className='bold'>5.5. </span> Nothing in this agreement operates so as to transfer any rights in any App Data or App Store Reviews to the User.
                    Such rights remain vested in the relevant App Store.</p>
                <p><span className='bold'>5.6. </span>Appinion has no relationship with any App Store. The Service depends on the continued availability of access by Appinion to
                    the App Data and App Store Reviews. In the event that any App Store prevents or limits Appinion's
                    access to App Data or App Store Reviews, the functionality or content of the Service and/or access to App Data may be reduced accordingly.
                    Appinion shall have no liability to a User in the event that an App Store prevents or limits Appinion's ability to access App Data and/or App Store Reviews.</p>
                <p><span className='bold'>5.7. </span> Appinion prohibits the use of login sharing, sharing of user credentials, shared passwords or the use of group email addresses.</p>

                <h5 className='iron mt-5 mb-4'>6. Intellectual Property</h5>
                <p><span className='bold'>6.1. </span> Appinion retains ownership of all intellectual property related to the service.</p>
                <p><span className='bold'>6.2. </span> Users are granted a limited license for the purpose of using the service.</p>
                <h5 className='iron mt-5 mb-4'>7. Privacy Policy</h5>
                <p>In accepting these Terms the User also confirms that it has read and accepts Appinion's Privacy Policy. The Privacy Policy can be read at <a href="/privacy">/privacy/.</a></p>
                <h5 className='iron mt-5 mb-4'>8. Termination</h5>
                <p>Either party may terminate the agreement for any reason with notice.</p>
                <h5 className='iron mt-5 mb-4'>9. Limitation of Liability</h5>
                <p>Appinion is not liable for any indirect, incidental, or consequential damages, limitations on liability regarding service interruptions or data loss.</p>
                <h5 className='iron mt-5 mb-4'>10. Changes to Terms</h5>
                <p>Appinion reserves the right to modify these terms with notice.</p>
                <h5 className='iron mt-5 mb-4'>11. Contact Information</h5>
                <a className='iron legend' href='mailto:main@appinion.pt'>main@appinion.pt</a>
                <footer className='mt-5 p-3 text-center'>
                    <p className='legend light-iron'>© Appinion 2023. All rights reserved</p>
                    <img className='ms-3' src={footer_appinion} alt="logo" style={{ height: 16 + 'px' }}></img>
                    <ul className='navbar-nav ms-auto'>
                        <li className='nav-item'>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}

export default Terms;