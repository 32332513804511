import React, { useState } from 'react';

import '../../styles/graphics.scss';

const SentimentGraphic = (data: any) => {
    const fullH = 160;

    const countBad = data.data.reviews[0].length
    const countNeutral = data.data.reviews.length > 1 ? data.data.reviews[1].length : 0
    const countGood = data.data.reviews.length > 2 ? data.data.reviews[2].length : 0

    const hBad = fullH * (countBad / (countBad + countNeutral + countGood))
    const hNeutral = fullH * (countNeutral / (countBad + countNeutral + countGood))
    const hGood = fullH * (countGood / (countBad + countNeutral + countGood))
    
    return (
        <div className="sentiment-card bg-cloudie me-1 ms-1">
            <div className="row sentiment-bar-container">
                <div className="text-center" style={{height:fullH+'px', width:16 + 'px', position:'relative'}}>
                    <div className="sentiment-bar bg-bloodie" style={{height:hBad + 'px'}}></div>
                </div>
                <div className="text-center" style={{height:fullH+'px', width:16 + 'px', position:'relative'}}>
                    <div className="sentiment-bar bg-ielou" style={{height:hNeutral + 'px'}}></div>
                </div>
                <div className="text-center" style={{height:fullH+'px', width:16 + 'px', position:'relative'}}>
                    <div className="sentiment-bar bg-greenie" style={{height:hGood + 'px'}}></div>
                </div>
            </div>
            <div className="text-center mt-2">
                <p className='iron semi-bold info'>{data.data.version}</p>
            </div>
        </div>
    )
}

function SentimentDistribution ({ reviews }: any) {
    const source = reviews;
    const uniqueVersions = [...new Set(source.map((review: Review) => review.version))];
    const sorted = uniqueVersions.sort(function(a: any, b:any) {
        return a.localeCompare(b, undefined, { numeric:true });
    });

    const reviewsByVersion = sorted.reverse().map((review: any) =>
        source.filter((d: Review) => d.version === review)
    );

    const graphicData: any[] = [];

    reviewsByVersion.forEach((review: any) => {
        const uniqueS = ["bad"," neutral", "good"];

        const reviewsBySentiment = uniqueS.map((s) =>
            review.filter((r: Review) => r.sentiment === s)
        );

        const reviewData = {
            version : review[0].version,
            reviews: reviewsBySentiment
        }

        graphicData.push(reviewData);
    });

    const sentiments = graphicData.map((info) => 
        <SentimentGraphic data = { info } />
    );

    return(
        <div className='rating-evolution p-3'>
            <div className='row flex-nowrap'>
                { sentiments }
            </div>
        </div>
    );

}

export default SentimentDistribution;