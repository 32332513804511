import React from 'react';

import '../../styles/graphics.scss';

function RatingEvolutionComponent ({ app, reviews }: any) {
    
    const source = reviews;
    const unique = [...new Set(source.map((review: Review) => review.version))];
    const sorted = unique.sort(function(a: any, b:any) {
        return a.localeCompare(b, undefined, { numeric:true });
    });

    const reviewsByVersion = sorted.reverse().map((review: any) =>
        source.filter((d: Review) => d.version === review)
    );

    const graphicData: any[] = [];

    reviewsByVersion.forEach((review: any) => {
        const total = review.reduce((a: any, v: any) => a = parseInt(a) + parseInt(v.rating), 0);
        const average = total / review.length;

        const reviewData = {
            version : review[0].version,
            average: average
        }

        graphicData.push(reviewData);
    });

    const data = graphicData.map((info) =>
        <div className='col me-1'>
            <div className='rating-version'>
                <div className='rating-bar bg-iron' style={{ height:(200 * info.average) / 5 + 'px' }}></div>
                <div><p className='iron semi-bold hint'> { info.average.toFixed(2) } </p></div>
            </div>
            <div className="text-center mt-2"><p className='iron semi-bold info'>{ info.version }</p></div>
        </div>
    );

    return(
        <div className='rating-evolution bg-cloudie p-3'>
            <div>
                <hr className='graph-line bottom'></hr>
                <hr className='graph-line middle'></hr>
                <hr className='graph-line top'></hr>
            </div>
            <div className='row flex-nowrap'>
                { data }
            </div>
        </div>
    );
}

export default RatingEvolutionComponent;