import React, { useState } from 'react';

function IssueOverallSentimentBar({ reviews }: any) {
    const badReviews = reviews.filter((d: any) => d.sentiment === 'bad');
    const goodReviews = reviews.filter((d: any) => d.sentiment === 'good');

    const total = reviews.length;

    const badWidth = (badReviews.length / total) * 100;
    const goodWidth = (goodReviews.length / total) * 100;

    return (
        <div>
            <div className='bg-doriean' style={{ width: 100 + '%', height: 8 + 'px', borderRadius: 5 + 'px' }}>
                <div className='bg-bloodie' style={{ width: badWidth + '%', height: 8 + 'px', borderRadius: 5 + 'px' }}>
                    <div className='bg-greenie' style={{ width: goodWidth + '%', height: 8 + 'px', borderRadius: 5 + 'px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default IssueOverallSentimentBar;