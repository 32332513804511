import React from 'react';
import { Animate } from "react-simple-animate";

import '../styles/mainbanner.scss';
import '../styles/typography.scss';
import "animate.css/animate.min.css";

import { Link } from 'react-router-dom';

function PublicMainBanner() {
    return (
        <div className='container-fluid main-banner'>
            <div className='container'>
                <div className='ps-3 pe-3'>
                    <Animate
                        play
                        start={{
                            opacity: 0,
                            transform: 'translateY(20px)'
                        }}
                        end={{
                            opacity: 1,
                            transform: 'translateY(0)'
                        }}
                        duration={0.33}
                        delay={0.66}>
                        <div className='row justify-content-center'>
                            <div className='col-auto section mt-5'>
                                <div className='row'>
                                    <div className='col-auto my-auto p-1'>
                                        <div className='bg-white version'>
                                            <b className='iron'>3.1</b>
                                        </div>
                                    </div>
                                    <div className='col-auto my-auto pe-5'>
                                        <p className='mb-0 white semibold'><b>What's new : </b>Appstore connect, Playstore console and e-mail notifications!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Animate>
                </div >
                <div className='row'>
                    <div className="col">
                        <div className='container p-4'>
                            <h1 className="large-heading text-center mt-5">Elevate the way you handle App reviews and ratings.</h1>
                            <p className="tagline text-center mt-4">
                                Your platform for monitoring and analysis.
                            </p>
                        </div>
                        <div className="row mt-5 text-center">
                            <div className='col'>
                                <Link to={"/subscribe"}>
                                    <a href="#cta_section" className="btn btn-light">Start now!</a>
                                </Link>
                                <p className='legend light-iron mt-2'></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default PublicMainBanner;