import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function KeywordsHitEvolution({ reviews, preview }: any) {

    const uniqueVersions = [...new Set(reviews.map((review: Review) => review.version))];
    var graphData: any[] = []

    uniqueVersions.map((version: any) =>
        graphData.push(reviews.filter((r: any) => r.version === version).length)
    )

    const options: ApexOptions = {
        legend: {
            show: false
        },
        tooltip: {
            enabled: preview === true ? false : true
        },
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        grid: {
            show: false
        },
        colors: ['#b1bd65', '#BD0000', '#EAD200'],
        stroke: {
            width: preview === true ? 1 : 2,
            curve: "smooth"
        },
        xaxis: {
            categories: uniqueVersions,
            labels: {
                show: false
            },
            axisBorder: {
                show: preview ? false : true
            },
            axisTicks: {
                show: preview ? false : true
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        }
    }

    var data = {
        series: [{
            name: "Hits",
            data: graphData
        }]
    };


    return (
        <div className='p-0'>
            <ReactApexChart options={options} series={data.series} type="line" height={preview === true ? 80 : 100} />
        </div>
    );
}

export default KeywordsHitEvolution;