import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import PrivateNavigationHeader from '../PrivateNavigationHeader';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import InfoBanner from '../InfoBanner';
import AlertComponent from '../AlertComponent';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import '../../styles/navigation.scss';
import '../../styles/stage.scss';

import revault_logo from '../../resources/footer_logo.png'
import placeholder from '../../resources/placeholder_icon.svg'

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function AppSettingsComponent(app: any) {
    const navigate = useNavigate();
    const location = useLocation() as any;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [countries, setCountries] = useState(null) as any[];

    const [icon, setIcon] = useState(location.state.app ? location.state.app.icon : null);
    const [iconUrl, setIconUrl] = useState("");
    const [iconName, setIconName] = useState(null);
    const [appName, setAppName] = useState(location.state.app ? location.state.app.name : null);
    const [country, setCountry] = useState(location.state.app ? location.state.app.country : null);
    const [countryCode, setCountryCode] = useState(location.state.app ? location.state.app.countryCode : null);
    const [appId, setAppId] = useState(location.state.app ? location.state.app.appleappid : null);
    const [keyID, setKeyId] = useState(location.state.app ? location.state.app.keyid : null);
    const [issuerId, setIssuerId] = useState(location.state.app ? location.state.app.issuerid : null);
    const [key, setKey] = useState(location.state.app ? location.state.app.key : null);
    const [packageId, setPackageId] = useState(location.state.app ? location.state.app.androidpackageid : null);
    const [bucketId, setBucketId] = useState(location.state.app ? location.state.app.androidbucketid : null);
    const [jsonFile, setJsonFile] = useState(location.state.app ? location.state.app.androidjsonfile : null);
    const [jsonFileName, setJsonFileName] = useState(null);
    const [notification, setNotification] = useState(location.state.app ? location.state.app.notify_email : null);


    if (!countries) {
        networking.getCountries().then((data: any) => {
            setCountries(data);
            if (country === null || country === undefined) {
                setDefaultCountry(data);
            }
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const loadAppIcon = (event: any) => {
        event.preventDefault();

        setError('');

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
        } else {
            setIcon(file);
            setIconUrl(URL.createObjectURL(file));
            setIconName(file.name);
        }

    }

    const updateAppName = (event: any) => {
        setError('');
        var m = event.target.value;

        setAppName(m);
    }

    function setDefaultCountry(data: any) {
        let list = data;
        let defaultCode = "US";

        let obj = list.filter((c: any) => c.code === defaultCode);
        let country = obj[0];
        setCountry(country.name);
        setCountryCode(country.code);
    }

    const updateCountry = (event: any) => {
        event.preventDefault();
        setError('');

        var m = event.target.value;
        var filtered = countries.filter((c: any) => c.name === m);
        var country = filtered[0];

        setCountry(country.name);
        setCountryCode(country.code);
    }

    const updateAppId = (event: any) => {
        setError('');
        var m = event.target.value;

        setAppId(m);
    }

    const updateIssuerId = (event: any) => {
        setError('');
        var m = event.target.value;

        setIssuerId(m);
    }

    const updateAppstoreConnectAPIKey = (event: any) => {
        setError('');
        var m = event.target.value;

        setKeyId(m);
    }

    const loadP8File = (event: any) => {
        event.preventDefault();

        setError('');

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
        } else {
            helper.base64For(file).then((result: any) => {
                setKey(result);
            }).catch((error: any) => {
                setError(error.message);
            });
        }

    }

    const updatePackageId = (event: any) => {
        setError('');
        var m = event.target.value;

        setPackageId(m);
    }

    const updateBucketId = (event: any) => {
        setError('');
        var m = event.target.value;

        setBucketId(m);
    }

    const updateNotification = (event: any) => {
        event.preventDefault();

        var status = event.target.value === 'on' ? true : false

        setLoading(true);
        networking.updateNotificationStatus(location.state.app.id, status).then((app: any) => {
            setLoading(false);
            navigate(-2);
        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setLoading(false);
        });
    }

    const loadJsonFile = (event: any) => {
        event.preventDefault();

        setError('');

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
        } else {
            helper.base64For(file).then((result: any) => {
                setJsonFile(result);
                setJsonFileName(file.name);
            }).catch((error: any) => {
                setError(error.message);
            });
        }

    }

    function saveApp() {
        var filtered = countries.filter((c: any) => c.name === country);
        var countryObj = filtered[0];
        setCountryCode(countryObj.code);

        if (appName === null) {
            window.scrollTo(0, 0);
            setError("Please add a name for your App.");

            return;
        }

        if (appId === null && packageId === null) {
            window.scrollTo(0, 0);
            setError("To add an App you need to insert at least an App Id or a playstore Id");

            return;
        }

        let app = {
            "icon": icon,
            "name": appName,
            "country": country,
            "androidPackageId": packageId,
            "countryCode": countryObj.code,
            "appleAppId": appId,
        }

        if (location.state.app) {
            setLoading(true);
            networking.updateApp(location.state.app.id, app, location.state.subscription).then((app: any) => {
                setLoading(false);
                navigate(-1);
            }).catch((error: Error) => {
                window.scrollTo(0, 0);
                setError(error.message);
                setLoading(false);
            });
        } else {
            setLoading(true);
            networking.saveApp(app, location.state.subscription).then((app: any) => {
                setLoading(false);
                navigate(-1);
            }).catch((error: Error) => {
                window.scrollTo(0, 0)
                setError(error.message);
                setLoading(false);
            });
        }
    }

    function deleteApp() {
        setLoading(true);
        networking.deleteApp(location.state.app.id).then((app: any) => {
            setLoading(false);
            navigate("/apps-list", { state: location.state.subscription });
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function connectToPlaystore() {
        if (bucketId === null || packageId === null || jsonFile === null) {
            window.scrollTo(0, 0);
            setError("Please, fill all the mandatory information.");

            return;
        }

        let data = {
            "androidJsonFile": jsonFile,
            "androidPackageId": packageId,
            "androidBucketId": bucketId,
            "connect": true
        }

        setLoading(true);
        networking.connectPlaystore(location.state.app.id, data).then((app: any) => {
            setLoading(false);
            navigate(-2);
        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setLoading(false);
        });
    }

    function connectToAppstoreConnect() {
        if (keyID === null || key === null || issuerId === null) {
            window.scrollTo(0, 0);
            setError("Please, fill all the mandatory information.");

            return;
        }

        let data = {
            "key": key,
            "keyid": keyID,
            "issuerid": issuerId,
            "connect": true
        }

        setLoading(true);
        networking.connectAppstoreConnect(location.state.app.id, data).then((app: any) => {
            setLoading(false);
            navigate(-2);
        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setLoading(false);
        });
    }

    function disconnectFromAppstoreConnect() {
        let data = {
            "key": null,
            "keyid": null,
            "issuerid": null,
            "connect": false
        }

        setLoading(true);
        networking.connectAppstoreConnect(location.state.app.id, data).then((app: any) => {
            setLoading(false);
            navigate(-2);
        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setLoading(false);
        });
    }

    function disconnectFromPlaystore() {
        let data = {
            "androidJsonFile": null,
            "androidPackageId": null,
            "androidBucketId": null,
            "connect": false
        }

        setLoading(true);
        networking.connectPlaystore(location.state.app.id, data).then((app: any) => {
            setLoading(false);
            navigate(-2);
        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setLoading(false);
        });
    }

    const goBack = (event: any) => {
        navigate(-1);
    }

    return (
        <div className='bg-cloudie'>
            <div className='apps container-fluid mb-5'>
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className='container'>
                        {error &&
                            <div>
                                <AlertComponent alert={error} />
                            </div>
                        }

                        <p className='iron semi-bold mb-0'>General information</p>
                        <p className='light-iron semi-bold legend mb-3'>Some information about your app.</p>
                        <div className='row card mb-3'>
                            <div className='row'>
                                <div className='col-sm row'>
                                    <div className='col-auto my-auto'>
                                        {
                                            iconUrl !== '' && <img className="app-icon my-auto" src={iconUrl} alt="app_logo" width={48}></img>
                                        }

                                        {
                                            icon !== '' && <img className="app-icon my-auto" src={icon} alt="app_logo" width={48}></img>
                                        }

                                        {
                                            iconUrl === '' && icon === '' && <img className="app-icon  my-auto" src={placeholder} alt="app_logo" width={48}></img>
                                        }
                                    </div>
                                    <div className='col'>
                                        <label
                                            className='light-iron semi-bold legend mb-2'>App icon
                                        </label>
                                        <input
                                            onChange={loadAppIcon}
                                            type="file"
                                            className="form-control form-control-sm"
                                            id="formFileSm"
                                            accept='image/png,image/jpeg' />
                                        <p className='light-iron legend my-2'>Max. size: 1Mb</p>
                                    </div>
                                </div>
                                <div className='col-sm'>
                                    <label className='light-iron semi-bold legend mb-2'>App name</label>
                                    <input value={appName} onChange={updateAppName} className='form-control' type="text" name="app_name" id="appName"></input>
                                </div>
                                <div className='col-sm'>
                                    <label className='light-iron semi-bold legend mb-2'>Country</label>
                                    <select value={country} onChange={(e: any) => updateCountry(e)} className='form-select form-select-lg mb-3' aria-label=".form-select-lg example">
                                        {
                                            countries &&
                                            countries.map((country: any, index: any) => {
                                                return (
                                                    <option><a className='dropdown-item iron legend' href="#">{country.name}</a></option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <p className='iron semi-bold mb-0 mt-5 mb-1'>Store identification</p>
                        <p className='light-iron semi-bold legend mb-0'>The Apple Id is an automatically generated ID assigned to your app. You can find your app
                            Apple Id on the App information section on your Appstore connect console.</p>
                        <p className='light-iron semi-bold legend'>The Package ID, uniquely identifies an Android app within the Google Play Store ecosystem.
                            It typically follows a reverse domain name format (e.g., com.example.myapp). For more information, <span className='ms-1'><Link to='/docs'>go to documentation page.</Link></span></p>
                        <div className='row gy-3'>
                            <div className='col-sm me-3 card'>
                                <label className='light-iron semi-bold legend mb-2'>Apple Id</label>
                                <input value={appId} onChange={updateAppId} className='form-control' type="text" name="app_name" id="appName"></input>

                            </div>
                            <div className='col-sm card'>
                                <div className='col-sm mb-3'>
                                    <label className='light-iron semi-bold legend mb-2'>Package id</label>
                                    <input value={packageId} onChange={updatePackageId} className='form-control' type="text" name="app_name" id="appName"></input>
                                </div>
                            </div>
                        </div>

                        <div className='container mt-5'>
                            <div className='row justify-content-end'>
                                {
                                    location.state.app &&
                                    <div className='col-auto'>
                                        <button onClick={deleteApp} className='btn bloodie legend bold'>Delete App</button>
                                    </div>
                                }
                                <div className='col-auto'>
                                    <button onClick={saveApp} className='btn bg-apporange white'>Save changes</button>
                                </div>
                            </div>
                        </div>

                        <p className='iron semi-bold mb-0 mt-5'>Connections</p>
                        <p className='light-iron semi-bold legend mb-3'>Connect your App to Appstore Connect and Google Playstore console
                            to access more reviews, more detailed meta data and reply to reviews. For more information, <span className='ms-1'><Link to='/docs'>go to documentation page.</Link></span>
                        </p>
                        <div className='row gy-3'>
                            <div className='col-sm me-3 card'>
                                <div className='row my-auto'>
                                    <div className='col my-auto mb-3'>
                                        <p className='iron semi-bold m-0 p-0'>Appstore connect</p>
                                    </div>
                                    {
                                        location.state.app && location.state.app.appstore_connect === true &&

                                        <div className='col-auto'>
                                            <button onClick={disconnectFromAppstoreConnect} className='btn bg-indigo white'>Disconnect</button>
                                        </div>
                                    }
                                </div>
                                {
                                    (!location.state.app || location.state.app.appstore_connect === false) &&
                                    <div>
                                        <label className='light-iron semi-bold legend mb-2'>Issuer</label>
                                        <input value={issuerId} onChange={updateIssuerId} className='form-control' type="text" name="app_name" id="appName"></input>
                                        <p className='mt-2 light-iron legend'>The Apple Id is an automatically generated ID assigned to your app. You can find your app Apple Id on the App information section on your Appstore connect console.</p>

                                        <label className='light-iron semi-bold legend mb-2'>API Key</label>
                                        <input value={keyID} onChange={updateAppstoreConnectAPIKey} className='form-control' type="text" name="app_name" id="appName"></input>
                                        <p className='mt-2 light-iron legend'>The Apple Id is an automatically generated ID assigned to your app. You can find your app Apple Id on the App information section on your Appstore connect console.</p>

                                        <label className='light-iron semi-bold legend mb-2'>Certificate</label>
                                        <div className='row'>
                                            <div className='col-auto'>
                                                <input onChange={loadP8File}
                                                    type="file"
                                                    className="form-control form-control-sm"
                                                    id="formFileSm"
                                                    accept='.p8' />
                                            </div>
                                            {
                                                key && <div className='col-auto my-auto'><FontAwesomeIcon icon={faFile} /><span className='iron hint bold ms-2'>{appName + '.json'}</span></div>
                                            }

                                        </div>
                                        <p className='iron bold legend mt-2'>Max. size: 1Mb</p>
                                        <div className='col'>
                                            <button onClick={connectToAppstoreConnect} className='btn bg-indigo white'>Connect</button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='col-sm me-3 card'>
                                <div className='row'>
                                    <div className='col my-auto mb-3'>
                                        <p className='iron semi-bold m-0 p-0'>Google Play Console</p>
                                    </div>
                                    {
                                        location.state.app && location.state.app.playstore_connect === true &&

                                        <div className='col-auto'>
                                            <button onClick={disconnectFromPlaystore} className='btn bg-indigo white'>Disconnect</button>
                                        </div>

                                    }
                                </div>
                                {
                                    (!location.state.app || location.state.app.playstore_connect === false) &&
                                    <div>
                                        <div className='col-sm mb-3'>

                                            <label className='light-iron semi-bold legend mb-2'>Bucket id</label>
                                            <input value={bucketId} onChange={updateBucketId} className='form-control' type="text" name="app_name" id="appName"></input>
                                            <p className='mt-2 light-iron legend'>The Bucket ID, in the context of Google Cloud Storage, uniquely identifies
                                                a storage container where files and data are stored. Refer to the documentation to find this information.</p>
                                        </div>
                                        <div className='col-sm mb-3'>
                                            <label className='light-iron semi-bold legend mb-2'>Android Json file</label>
                                            <div className='row'>
                                                <div className='col-auto'>
                                                    <input onChange={loadJsonFile} type="file" className="form-control form-control-sm" id="formFileSm" accept='application/json' />
                                                </div>
                                                {
                                                    jsonFile && <div className='col-auto my-auto'><FontAwesomeIcon icon={faFile} /><span className='iron hint bold ms-2'>{appName + '.json'}</span></div>
                                                }

                                            </div>
                                            <p className='iron bold legend mt-2'>Max. size: 1Mb</p>
                                            <div className='col-auto'>
                                                <button onClick={connectToPlaystore} className='btn bg-indigo white'>Connect</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <p className='iron semi-bold mb-0 mt-5'>E-mail notifications</p>
                        <p className='light-iron semi-bold legend mb-3'>Manage your e-mail notifications.</p>
                        <div className='card'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='iron semi-bold mb-1 p-0 legend'>Weekly Summary</p>
                                    <p className='light-iron legend m-0 p-0'>Receive a weekly update for your reviews.</p>
                                </div>
                                <div className='col-auto my-auto'>
                                    <div className="form-check form-switch">
                                        {notification === true &&
                                            <input onChange={updateNotification} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked></input>
                                        }
                                        {notification === false &&
                                            <input onChange={updateNotification} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"></input>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <footer className='p-3 text-center'>
                <a className='no-decoration' href='https://www.appinion.pt' target={'blank'}>
                    <p className='legend light-iron'>© 2024 Appinion All Rights Reserved.</p>
                    <img className='ms-3' height={16} src={revault_logo} alt="logo"></img>
                </a>
            </footer>
        </div >

    );
}

export default AppSettingsComponent;