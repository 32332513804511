import React from 'react';

function EmptyMessageComponent({ image, title, description }: any) {

    return (
        <div className='container text-center'>
            <img className='mb-5' src={''}></img>
            <p className='iron bold'>{title}</p>
            <p className='light-iron info'>{description}</p>
        </div>
    );
}

export default EmptyMessageComponent;