import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import mockup_1 from '../../resources/rvt-il-1.png'
import mockup_3 from '../../resources/rvt-il-3.png'

import '../../styles/navigation.scss';
import '../../styles/stage.scss';

const networking = require('../../Networking/API');

function ManageStageComponent( { app, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const [message3, setMessage3] = useState('');
    const [question, setQuestion] = useState('');
    const [items, setItems] = useState([]) as any[];
    const [message, setMessage] = useState('')
    const [step, setStep] = useState(1);
    const [error, setError] = useState('');

    const updateName = (event: any) => {
        setError('');

        var m = event.target.value;

        setName(m);
    }

    const updateMessage1 = (event: any) => {
        setError('');
        var m = event.target.value;

        setMessage1(m);
    }

    const updateMessage2 = (event: any) => {
        setError('');
        var m = event.target.value;

        setMessage2(m);
    }

    const updateMessage3 = (event: any) => {
        setError('');
        var m = event.target.value;

        setMessage3(m);
    }

    const updateQuestion = (event: any) => {
        setError('');
        var m = event.target.value;
        setQuestion(m);
    }

    function addQuestion() {
        setError('');
        setItems(
            [
            ...items,
            question
            ]
        );
    }

    function updateStep(newStep: any) {
        setStep(newStep)

        if (newStep === 1) {
            setMessage(message1);
        }

        if (newStep === 2) {
            setMessage(message2);
        }

        if (newStep === 3) {
            setMessage(message3);
        }
    }

    function updatePreview() {
        setStep(1);
        updateStep(step);
    }

    function saveStage() {
        setLoading(true);

        const data = { 
                'name': name, 
                'status': 'active', 
                'questions': items , 
            'first_message': message1, 
            'second_message': message2, 
            'final_message': message3 
        }

        if(data.name.length == 0) {
            setError("Please, insert a name for your stage.");
            return;
        }

        if(data.first_message.length == 0) {
            setError("Please, insert a message for the first step.");
            return;
        }

        if(data.second_message.length == 0) {
            setError("Please, insert a message for the second step.");
            return;
        }

        if(data.final_message.length == 0) {
            setError("Please, insert a message for the last step.");
            return;
        }

        if (data.questions.length == 0) {
            setError("You need to have at least one question.");
            return;
        }

        networking.saveStage(app, data).then((stage: any) => {
            setLoading(false);
            handler(true);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }


    function removeQuestion (question: string) {
        setItems(
            items.filter((a: any) =>
                a !== question
              )
        );
    }

    if (items) {
        var questionList = items.map((question: string) =>
            <div>
                <div className='row p-2'>
                    <div className='col'>{ question }</div>
                    <div className='col-auto'>
                        <button className='btn btn-danger' onClick={ () => removeQuestion(question) }> <FontAwesomeIcon icon={ faTrashCan } color='#fff'/> </button>
                    </div>
                </div>
                <hr></hr>
            </div>
        );

        var previewQuestions = items.map((question: string) =>
            <div>
                <div className='row p-1'>
                    <div className='ps-5 col'>
                        <p className='iron legend m-1'>{ question }</p>
                    </div>
                    <div className='col-auto pe-5'>
                        <div className='check'></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='feedback-manager'>
            { loading ?
                <LoadingSpinnerComponent/>
                :
            <div className='container card'>
                    { error &&
                        <div>
                            <AlertComponent alert={ error } />
                        </div>
                    }

                    <h5 className='apporange mb-5'>Stage information</h5>                    
                    <div className='row'>
                        <div className='col new-stage'>
                            <div className='col-sm mb-3'>
                                <label className='light-iron semi-bold legend mb-1'>Stage name</label>
                                <input className='form-control' type="text" name="app_name" id="appName" onChange={ updateName }></input>
                            </div>
                            <div className='col-sm mb-3'>
                                <label className='light-iron semi-bold legend mb-1'>Ask step message</label>
                                <input className='form-control' type="text" name="app_name" id="appName" onChange={ updateMessage1 }></input>
                            </div>
                            <div className='col-sm mb-3'>
                                <label className='light-iron semi-bold legend mb-1'>Feedback step message</label>
                                <input className='form-control' type="text" name="app_name" id="appName" onChange={ updateMessage2 }></input>
                            </div>
                            <div className='col-sm mb-3'>
                                <label className='light-iron semi-bold legend mb-1'>Final message</label>
                                <input className='form-control' type="text" name="app_name" id="appName" onChange={ updateMessage3 }></input>
                            </div>
                            <div className='col-sm mb-3'>
                                <label className='light-iron semi-bold legend mb-1'>Question</label>
                                <div className='row'>
                                    <div className='col'>
                                        <input className='form-control' type="text" name="app_name" id="appName" onChange={ updateQuestion }></input>
                                    </div>
                                    <div className='col-auto my-auto'>
                                        <button className='btn btn-outline legend bold my-auto' onClick= { addQuestion }> <FontAwesomeIcon icon={ faAdd } /> </button>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-cloudie p-3'>
                                { questionList }
                            </div>
                            <div>
                                <button onClick={ updatePreview } className='btn btn-outline iron legend bold'>Update preview</button>
                            </div>
                            <div>
                                <button onClick={ saveStage } className='btn btn-cta-primary bg-apporange legend bold mt-3'>Save stage</button>
                            </div>
                        </div>
                        <div className='col text-center'>
                            <p className='iron bold'>Preview</p>
                            <p className='light-iron semi-bold legend ps-5 pe-5'><b>NOTE: </b>This is a preview of your feedback query. It may not look exactly like this on the real device.</p>
                            <div className='preview bg-cloudie mx-auto'>
                                { step == 1 &&
                                    <div className='mt-5 mb-5'>
                                        <img src={ step == 1 ? mockup_1 : mockup_3} alt="preview"></img>
                                    </div>
                                }
                                { step == 3 &&
                                    <div className='mt-5 mb-5'>
                                        <img src={ mockup_3} alt="preview"></img>
                                    </div>
                                }
                                <div className='ps-5 pe-5 mt-5'>
                                    <h6 className='iron regular'>{ message }</h6>
                                </div>
                                <div>
                                    { step == 1 &&
                                        <p className='iron bold mt-5'>Are you enjoying?</p>
                                    }
                                </div>
                                { step == 2 &&
                                    <div>
                                        <div className='mt-5 mb-5'> { previewQuestions }</div>
                                        <p className='iron bold hint'>Provide more information:</p>
                                        <div className='ps-5 pe-5 mb-5'>
                                            <textarea className="form-control" id="recipient-name"></textarea>
                                        </div>
                                    </div>
                                }
                                { step == 1 &&
                                    <div className=''>
                                        <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                        <p className='bold white m-0'>Yes</p>                                      
                                        </div>
                                        <div className='mx-auto p-1 preview-btn'>
                                            <p className='bold iron m-0'>No</p>
                                        </div>
                                    </div>
                                }
                                { step == 2 &&
                                    <div className=''>
                                        <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                            <p className='bold white m-0'>Send</p>                                      
                                        </div>
                                        <div className='mx-auto p-1 preview-btn'>
                                            <p className='bold iron m-0'>Not now</p>
                                        </div>
                                    </div>
                                }
                                { step == 3 &&
                                    <div className='mx-auto mb-3 p-1 preview-btn bg-greenie mt-5'>
                                        <p className='bold white m-0'>Close</p>                                      
                                    </div>
                                }
                            </div>
                            <div className='row justify-content-center mt-3'>
                                <div className='col-auto'>
                                    <button className='btn legend bold' onClick={ () => updateStep(1) }>1</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='btn legend bold' onClick={ () => updateStep(2) }>2</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='btn legend bold' onClick={ () => updateStep(3) }>3</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                }
        </div>

    );
}

export default ManageStageComponent;