import React from 'react';

import '../styles/paginator.scss';

function PaginationComponent({ itemsPerPage, totalItems, paginate, currentPage }: any) {
    var pageNumbers = [];
    let totalPages = Math.ceil(totalItems / itemsPerPage)

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <nav className="text-center">
                <ul className="pagination pagination-sm">
                    {pageNumbers.map(number => (
                        <li key={number} className={currentPage === number ? "page-item active" : "page-item"}>
                            <a onClick={() => paginate(number)} className="page-link">
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

export default PaginationComponent;