import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import RatingEvolutionComponent from './RatingEvolutionComponent';
import SentimentEvolution from './SentimentEvolution';
import SentimentDistribution from './SentimentDistribution';
import PredictTopComponent from '../Predictor AI/PredictorTopComponent';
import PredictRoadmapComponent from '../Predictor AI/PredictorRoadmapComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faAppStore } from '@fortawesome/free-brands-svg-icons'

import '../../styles/dropdowns.scss';

const networking = require('../../Networking/API');

function HeartBeatComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [currentStore, setCurrentStore] = useState('');

    var versions: any[] = [];

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    var currentItems = reviews;

    if (reviews) {
        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');
        }
    }

    return (
        <div className='row gy-3'>
            <div className='p-2 bg-doriean filter-container'>
                <div className='dropdown mb-1'>
                    <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                        <span className='me-2'>{currentStore === '' ? 'All stores' : currentStore}</span>
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faStore} /></span> All stores</a></li>
                        <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faAppStore} /></span> Appstore</a></li>
                        <li><a onClick={(e) => filterByStore(e)} className='dropdown-item'><span className="me-2"><FontAwesomeIcon icon={faGooglePlay} /></span> Playstore</a></li>
                    </ul>
                </div>
            </div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col'>
                    {
                        currentItems && currentItems.length == 0 &&
                        <div className='card mt-3'>
                            <EmptyMessageComponent
                                image={''}
                                title={'We were not able to generate your heartbeat.'}
                                description={'We\'ve not found any reviews. Please try another search criteria.'} />
                        </div>
                    }
                    {currentItems && currentItems.length != 0 &&
                        <div>
                            <div className='card reviews-card mb-3'>
                                <p className='light-iron semi-bold mb-4'>Average rating per version</p>
                                {reviews && <RatingEvolutionComponent app={app} reviews={currentItems} />}
                            </div>
                            <div className='card reviews-card mb-3'>
                                <p className='light-iron semi-bold mb-4'>Sentiment # per version</p>
                                {reviews && <SentimentEvolution reviews={currentItems} />}
                            </div>
                            <div className='card reviews-card'>
                                <p className='light-iron semi-bold mb-4'>Sentiment % per version</p>
                                {reviews && <SentimentDistribution reviews={currentItems} />}
                            </div>
                        </div>
                    }
                </div>
            }
        </div >
    );

}

export default HeartBeatComponent;