import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import AlertComponent from '../AlertComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import '../../styles/predictor.scss';

const networking = require('../../Networking/API');
const cookies = new Cookies();

function PredictorPersonaGenerator({ app, type }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState(cookies.get(type)) as any[];

    function getData() {
        setLoading(true);

        networking.getTopData(app.id, type).then((data: any) => {
            createPersona(data.content);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function createPersona(data: string) {
        let object = JSON.parse(data);

        setData(object);
    }

    function regenerate() {
        getData();
    }

    function share() {
        var shareMessage = "Please, take a look to our users persona: \n\n";
        const disclaimer = "Made with 🧠 using appinion. https://www.appinion.pt";
        const persona = data.name + ', \n\n' + data.description;

        shareMessage += persona + '\n\n';

        const problemSetup = data.problem;

        shareMessage += problemSetup + '\n\n';

        const goalSetup = data.name + ' goal is ' + data.goal;

        shareMessage += goalSetup + '\n\n';

        const triggerSetup = data.triggers;

        shareMessage += triggerSetup + '\n\n';

        var painsList = "";
        var benefits = "";
        var doubts = "";

        data.pain_points.forEach((item: any) => {
            let result = item + "\n";
            painsList += result;
        });

        data.benefits.forEach((item: any) => {
            let result = item + "\n";
            benefits += result;
        });

        data.doubts.forEach((item: any) => {
            let result = item + "\n";
            doubts += result;
        });

        shareMessage += data.name + ' main pains are: \n' + painsList + '\n\n';
        shareMessage += data.name + ' wants: \n' + benefits + '\n\n';
        shareMessage += 'This doubts, slow ' + data.name + ' down: \n' + doubts + '\n\n';

        shareMessage += "\n" + disclaimer;

        navigator.clipboard.writeText(shareMessage);
    }

    return (
        <div className=''>
            {error && <div> <AlertComponent alert={error} /> </div>}

            <div className='row'>
                <div className='col'>
                    <h3 className='iron bold'>
                        Snapshot
                    </h3>
                    <p className='light-iron legend'>
                        Based on the latest <b>50</b> reviews of your App, the <b>Snapshot</b> tool will generate a
                        Persona of your typical user.
                        Just hit the <b>Generate</b> button.
                    </p>
                </div>
                <div className='col-auto'>
                    {data &&
                        <div className='row gx-3'>
                            <div className='col-auto'>
                                <button type="button" onClick={share} className='btn iron bold legend'>
                                    <span className='me-1'><FontAwesomeIcon icon={faCopy} color='#000000' /></span> Copy
                                </button>
                            </div>
                            <div className='col-auto'>
                                <button type="button" onClick={regenerate} className='btn btn-cta-primary bg-apporange white bold legend'>
                                    Update
                                </button>
                            </div>
                        </div>
                    }
                    {!data &&
                        <div className='row mt-2'>
                            <button type="button" onClick={getData} className='btn btn-cta-primary bg-apporange white bold legend'>
                                Generate
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className=''>
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className='container-fluid'>
                        {data &&
                            <div className='row mt-3 gx-3'>
                                <div className='card mb-3'>
                                    <h3 className='bold'>{data.name}</h3>
                                    <p>{data.description}</p>
                                </div>
                                <div className=' container card mb-3'>
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Problem</h3>
                                                <h5 className='mt-0 light-iron regular'>Your persona struggles from this issue.</h5>
                                                <p className='mt-4'>{data.problem}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Pains</h3>
                                                <h5 className='mt-0 light-iron regular'>Especially from these negative experiences.</h5>
                                                <ul className='mt-4'>
                                                    {data.pain_points && data.pain_points.map((i: any, index: any) => (
                                                        <li className='m-0'>
                                                            <p>{i}</p>
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=' container card mb-3'>
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Goal</h3>
                                                <h5 className='mt-0 light-iron regular'>Your Persona wants to achieve this transformation.</h5>
                                                <p className='mt-4'>{data.goal}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Benefits</h3>
                                                <h5 className='mt-0 light-iron regular'>So she/he can experience these positive experiences.</h5>
                                                <ul className='mt-4'>
                                                    {data.benefits && data.benefits.map((i: any, index: any) => (
                                                        <li className='iron info m-0'>
                                                            <p>{i}</p>
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=' container card mb-3'>
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Trigger</h3>
                                                <h5 className='mt-0 light-iron regular'>This pushed your persona to take action.</h5>
                                                <p className='mt-4'>{data.triggers}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg'>
                                            <div className='p-4'>
                                                <h3 className='semi-bold'>Doubts</h3>
                                                <h5 className='mt-0 light-iron regular'>But these doubts slow her/him down.</h5>
                                                <ul className='mt-4'>
                                                    {data.doubts && data.doubts.map((i: any, index: any) => (
                                                        <li className='iron info m-0'>
                                                            <p>{i}</p>
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default PredictorPersonaGenerator;