import React, { useState } from 'react';
import KeywordHitEvolution from './KeywordHitEvolution';

import '../../styles/typography.scss';
import '../../styles/reviews.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

import ReviewComponent from '../Reviews/ReviewComponent';
import PillsList from '../PillsListComponent';
import KeywordPopUpComponent from './KeywordPopUpComponent';

const networking = require('../../Networking/API');

function ReviewsDistributionByIssue ({ reviews, issues, handler }: any) {
    const [keywordToAdd, setKeywordToAdd] = useState(false);
    const [currentIssue, setCurrentIssue] = useState(false);

    var distributed: any[] = [];
    var graphData:any[] = []
    
    function removeIssue(issue: any) {
        networking.removeIssue(issue.id).then((stage: any) => {
            handler(true);
        }).catch((error: Error) => {
        });
    }
    
    function addKeyword(issue: any) {
        setCurrentIssue(issue);
        setKeywordToAdd(true)
    }

    function deleteKeyword(keyword: any, issue: any) {
        const newKw = issue.keywords.filter((kw: string) => { return kw !== keyword });

        networking.removeKeyword(issue, newKw).then((stage: any) => {
            handler(true);
        }).catch((error: Error) => {
        });
    }

    function keywordSaved() {
        handler(true);
    }

    issues.map((issue: any, index: any) =>
        distributed.push({ "id": issue.id,
                        "issue": issue.title,
                        "keywords": issue.keywords,
                        "index": index,
                        "reviews": reviews.filter((review: Review) => issue.keywords.some( (v:any) => review.content.toLocaleLowerCase().includes(v.toLocaleLowerCase()))) 
                    })
    );

    issues.map((issue: any) => 
        graphData.push(reviews.filter((review: Review) => issue.keywords.some( (v:any) => review.content.toLocaleLowerCase().includes(v.toLocaleLowerCase()))).length)
    );

    const distribution = distributed.map((issue: any) =>
        <div>
            { keywordToAdd &&
                <KeywordPopUpComponent issue = { currentIssue } title= 'Add new keywords' message = '' handler = { keywordSaved }/>
            }
            { 
                <div className='accordion-item mb-2'>
                    <p className='iron bold accordion-header' id={'flush-heading ' + issue.index } >
                        <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={ '#flush-collapse' + issue.index }>
                            <p className='iron semi-bold m-0'>{ issue.issue } | <span className='light-iron hint'>{ issue.reviews.length } hits</span></p>
                        </button>
                    </p>
                    <div className='accordion-collapse collapse' id={'flush-collapse' + issue.index}>
                        <div className=''>
                                <div className='p-3'>
                                    <div className="row">
                                        <div className='col me-5'>
                                            <div className='mt-3 mb-3 bg-doriean progress'>
                                                <div className="bg-greenie progress m-0" style={{width:((issue.reviews.length / reviews.length) * 100) +'%'}}></div>
                                            </div>
                                            <div className=""><span className="iron semi-bold legend">Total : { issue.reviews.length } from { reviews.length }</span> | <span className="iron bold">{ ((issue.reviews.length / reviews.length) * 100).toFixed(0) } %</span></div>
                                        </div>
                                        <div className='col'>
                                            <KeywordHitEvolution reviews= { issue.reviews }/>
                                        </div>
                                        <div className='col-auto my-auto ps-4 pe-4'>
                                            <button className='btn btn-danger' onClick={ () => removeIssue(issue) }> <FontAwesomeIcon icon={ faTrashCan } color='#fff'/> </button>
                                        </div>
                                        <div className='bg-cloudie mb-3 p-2'>
                                            <div className='row ps-5 mt-2'>
                                                <div className='col-auto mt-1 mb-0'>
                                                    <PillsList issue = { issue } keywords= { issue.keywords } color = 'light' show = { true } handler = { deleteKeyword } />
                                                </div>
                                                <div className='col-auto mt-1'>
                                                    <button data-bs-toggle="modal" data-bs-target="#keywordModal" onClick={ () => addKeyword(issue) } className='btn btn-outline'><FontAwesomeIcon icon={ faAdd } color='#c1c1c1'/></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='review-list'>
                                            {
                                                issue.reviews.map((rev: any) => 
                                                    <ReviewComponent review = { rev }/>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );

    return(
        <div className='accordion' id='reviewAccordion'>
            { distribution }
        </div>
    )
}

export default ReviewsDistributionByIssue;