import React from 'react';
import PillsList from '../PillsListComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faMobile , faCodeBranch, faCode, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'

import '../../styles/reviews.scss';
import { faSkyatlas } from '@fortawesome/free-brands-svg-icons';

const helper = require('../../Helpers/helpers');

function FeedbackResultComponent({ feedback }: any) {

    return(
        <div className='mb-1 pb-2'>
            <div className="row mt-2 review-item border-bottom">
                <div className="col-md-2 me-3 border-end">
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faMobile} /></span>{feedback.device}</div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faCode} /></span>{ feedback.os }</div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faCodeBranch} /></span>{ feedback.version }</div>
                    { feedback.email && <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faEnvelope} /></span>{ feedback.email }</div> }
                    { feedback.phone && <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faPhone} /></span>{ feedback.phone }</div> }
                </div>
                
                <div className='col'>
                    <div className='row'>
                        <div className="ml-0">
                            <div className='row mb-3'>
                                <div className='col-auto'>
                                    { feedback.user_id && <div className="iron hint">{ feedback.user_id }</div> }
                                </div>
                                <div className='col-auto'>
                                    { feedback.date && <div className="light-iron bold hint">{ helper.timeSince(feedback.date) }</div> }
                                </div>
                            </div>
                            <p className="content">{feedback.feedback}</p>
                            <div><PillsList keywords= { feedback.answers }/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedbackResultComponent;