import { Buffer } from 'buffer';

//const host = "http://localhost:8080/"
const host = "https://revault-api.herokuapp.com/"
const appsecret = "rvt_test"
const apikey = "f2d4d9f9-2aa2-4210-8ab7-d86765fc43c3"

function authorize() {
    const auth = appsecret + ':' + apikey
    const encoded = Buffer.from(auth).toString('base64');

    return encoded
}

export const sendPlaystoreReply = async function (appId: string, storeId: string, reply: string) {
    const endpoint = host + "reviews/" + appId + "/playstore/reply";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ 'id': storeId, 'replyText': reply })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const sendAppstoreReply = async function (appId: string, storeId: string, reply: string) {
    const endpoint = host + "reviews/" + appId + "/appstore/reply";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ 'id': storeId, 'replyText': reply })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const authenticateSubscription = async function (subscriptionId: string) {
    const endpoint = host + 'subscriptions/' + subscriptionId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const subscriptionData = await response.json();

    return subscriptionData;
}

export const appsFor = async function (subscriptionId: string) {
    const endpoint = host + 'apps/' + subscriptionId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const apps = await response.json();

    return apps;
}

export const reviewsFor = async function (appId: string) {
    const endpoint = host + "reviews/" + appId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const reviews = await response.json();

    return reviews;
}

export const ratingsFor = async function (appId: string, type: string) {
    const endpoint = host + "ratings/" + type + "/" + appId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const rating = await response.json();

    return rating;
}

export const issuesFor = async function (appId: string) {
    const endpoint = host + "issues?app=" + appId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const issues = await response.json();

    return issues;
}

export const addIssue = async function (appId: any, name: string, kw: string) {
    const endpoint = host + "issues";
    var kws = kw.split(",")

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'app': appId.id,
                'title': name,
                'keywords': kws,
                'state': 'New'
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const updateIssueState = async function (issue: any, kw: string, state: string) {
    const endpoint = host + "issues/" + issue.id;
    var kws = kw.split(",")

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'title': issue.title,
                'keywords': kws,
                'state': state
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const updateIssue = async function (issue: any, kw: string) {
    const endpoint = host + "issues/" + issue.id;
    var kws = kw.split(",")

    var list = issue.keywords.concat(kws)

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'title': issue.issue,
                'keywords': list,
                'state': 'New'
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const removeKeyword = async function (issue: any, kw: any) {
    const endpoint = host + "issues/" + issue.id;

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'title': issue.issue,
                'keywords': kw
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const removeIssue = async function (id: string) {
    const endpoint = host + "issues/" + id;

    const response = await fetch(
        endpoint,
        {
            method: 'DELETE',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const message = await response.json();

    return message;
}

export const getCountries = async function () {
    const endpoint = "https://restcountries.com/v3.1/all"

    const response = await fetch(
        endpoint,
        { method: 'GET' });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const countries = await response.json();
    var list: any[] = []

    countries.forEach((country: any) => {
        list.push({
            name: country.name.common,
            code: country.cca2
        })
    });

    return list.sort((a: any, b: any) => a.name.localeCompare(b.name));
}

export const getStages = async function (appId: string) {
    const endpoint = host + "stages/" + appId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const stages = await response.json();

    return stages;
}

export const getQueriesForStage = async function (stageId: string) {
    const endpoint = host + "results/" + stageId;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const saveStage = async function (app: any, data: any) {
    const endpoint = host + "stages/" + app.id;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'id': data.id,
                'app': app,
                'name': data.name,
                'status': data.status,
                'questions': data.questions,
                'first_message': data.first_message,
                'second_message': data.second_message,
                'final_message': data.final_message
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const updateStage = async function (id: any, status: any) {
    const endpoint = host + "stages/" + id;

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'status': status,
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const deleteStage = async function (id: any) {
    const endpoint = host + "stages/" + id;

    const response = await fetch(
        endpoint,
        {
            method: 'DELETE',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const saveApp = async function (app: any, subscription: any) {
    const endpoint = host + "apps";

    let url = '';

    if (app.icon) {
        url = await uploadFile(app.icon, app.name);
    }

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'subscription': subscription.id,
                'icon': url,
                'url': "https://itunes.apple.com/" + app.countryCode + "/rss/customerreviews/page=1/id=" + app.appleAppId + "/sortby=mostrecent/json",
                'name': app.name,
                'country': app.country,
                'androidjsonfile': app.androidJsonFile,
                'androidpackageid': app.androidPackageId,
                'androidbucketid': app.androidBucketId,
                'countrycode': app.countryCode.toLowerCase(),
                'appleappid': app.appleAppId,
                'issuerid': app.issuerid,
                'keyid': app.keyid,
                'key': app.key,
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;

}

export const updateApp = async function (id: any, app: any, subscription: any) {
    const endpoint = host + "apps/" + id;

    let url = '';

    if (app.icon) {
        url = await uploadFile(app.icon, app.name);
    }

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'icon': url,
                'url': "https://itunes.apple.com/" + app.countryCode + "/rss/customerreviews/page=1/id=" + app.appleAppId + "/sortby=mostrecent/json",
                'name': app.name,
                'country': app.country,
                'androidjsonfile': app.androidJsonFile,
                'androidpackageid': app.androidPackageId,
                'androidbucketid': app.androidBucketId,
                'countrycode': app.countryCode.toLowerCase(),
                'appleappid': app.appleAppId,
                'issuerid': app.issuerid,
                'keyid': app.keyid,
                'key': app.key,
                'playstore_connect': app.playstore_connect,
                'appstore_connect': app.appstore_connect,
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;

}

export const updateNotificationStatus = async function (id: any, status: boolean) {
    const endpoint = host + "apps/" + id + "/notification/update";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'status': status
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const connectPlaystore = async function (id: any, data: any) {
    const endpoint = host + "apps/" + id + "/playstore/connect";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'androidjsonfile': data.androidJsonFile,
                'androidbucketid': data.androidBucketId,
                'androidpackageid': data.androidPackageId,
                'connect': data.connect
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const connectAppstoreConnect = async function (id: any, data: any) {
    const endpoint = host + "apps/" + id + "/appStore/connect";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'key': data.key,
                'keyid': data.keyid,
                'issuerid': data.issuerid,
                'connect': data.connect
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const deleteApp = async function (id: any) {
    const endpoint = host + "apps/" + id;

    const response = await fetch(
        endpoint,
        {
            method: 'DELETE',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const customer = async function (session: String) {
    var endpoint = host + "subscriptions/customer";

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'session': session
            })
        });

    const success = await response.json();

    return success;
}

export const checkoutSubscription = async function (plan: any, price: any) {
    var priceID = ''

    if (plan === 'AA') {
        if (price === 0) {
            priceID = "price_1OY4FDJ7VM41098ssOB8rsHi"
        } else {
            priceID = "price_1OY4FDJ7VM41098sO9fVnOXj"
        }
    }

    if (plan === 'AAA') {
        if (price === 0) {
            priceID = "price_1OY4IGJ7VM41098scPU1fiCP"
        } else {
            priceID = "price_1OVdX3J7VM41098slmeKuSlO"
        }
    }

    var endpoint = host + "subscriptions/checkout";
    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'price': priceID
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;

}

export const customerPortal = async function (subscription: any, currentEndpoint: string) {
    var endpoint = host + "subscriptions/customerPortal";
    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'url': currentEndpoint,
                'customer': subscription.customerid
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;

}

export const saveNewSubscription = async function (subscription: any) {
    var endpoint = host + "subscriptions";

    let url = '';

    if (subscription.profileimage) {
        url = await uploadFile(subscription.profileimage, subscription.name);
    }

    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'creationdate': d,
                'expirationdate': c,
                'name': subscription.name,
                'profileimage': url,
                'businessname': subscription.businessName,
                'plan': subscription.plan,
                'email': subscription.email,
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const updateSubscription = async function (subscription: any) {
    const endpoint = host + "subscriptions/" + subscription.id;

    let url = '';

    if (subscription.profileimage) {
        url = await uploadFile(subscription.profileimage, subscription.name + Date());
    }

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'name': subscription.name,
                'profileimage': url,
                'businessname': subscription.businessName,
                'plan': subscription.plan,
                'email': subscription.email,
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const updateState = async function (subscription: String, sessionId: String, customerId: String, subscriptionId: String) {
    const endpoint = host + "subscriptions/status/" + subscription;

    const response = await fetch(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'checkoutsession': sessionId,
                'customerid': customerId,
                'subscriptionid': subscriptionId,
                'status': 'active',
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const downloadReport = async function (app: any, month: any, year: any) {

    let monthNumber = new Date(`${month} 1, ${year}`).getMonth() + 1;

    const endpoint = host + "report/" + app.id + "?month=" + monthNumber + "&year=" + year;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.blob();

    return success;
}

export const uploadFile = async function (file: any, name: any) {
    const endpoint = 'https://api.cloudinary.com/v1_1/dwggfdgyw/image/upload'

    const data = new FormData();
    data.append('file', file);
    data.append('public_id', name);
    data.append('upload_preset', 'ml_default');

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            body: data
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const responseData = await response.json();
    const secureUrl = responseData.secure_url;

    return secureUrl;
}

export const getTopData = async function (app: any, type: any) {
    const endpoint = host + "predictor/" + app + "?type=" + type;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}
