import React, { useState } from 'react';

import PredictTopComponent from './PredictorTopComponent';
import PredictRoadmapComponent from './PredictorRoadmapComponent';
import PredictorPersonaGenerator from './PredictorPersonaGenerator';

const networking = require('../../Networking/API');

function PersonaGenerator(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    return (
        <div>
            <div className='row gy-3 gx-3 mt-3' >
                <div className='col'>
                    <div className='card bg-doriean' style={{ height: 100 + '%' }}>
                        <PredictorPersonaGenerator app={app.app} type={'persona'} />
                    </div>
                </div>
            </div>
        </div>

    );

}

export default PersonaGenerator;