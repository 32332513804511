import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import AlertComponent from '../AlertComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import '../../styles/predictor.scss';

const networking = require('../../Networking/API');
const cookies = new Cookies();

function PredictRoadmapComponent({ app, type }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState(cookies.get(type)) as any[];

    function getData() {
        setLoading(true);

        networking.getTopData(app.id, type).then((data: any) => {
            createRoadmap(data.content);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function createRoadmap(data: string) {
        let object = JSON.parse(data);

        setData(object);
    }

    function share() {
        var shareMessage = "Please, take a look on what we could do next: \n\n";
        const disclaimer = "Made using appinion. https://www.appinion.pt";

        data.forEach((item: any) => {
            let result = item.description + "\n";
            shareMessage += result;
        });

        shareMessage += "\n" + disclaimer;

        navigator.clipboard.writeText(shareMessage);
    }

    function regenerate() {
        getData();
    }

    return (
        <div className=''>
            {error && <div> <AlertComponent alert={error} /> </div>}

            <div className='row'>
                <div className='col'>
                    <h3 className='iron bold'>
                        Pathfinder
                    </h3>
                    <p className='light-iron legend'>
                        Based on the latest <b>50</b> reviews of your App, the <b>Pathfinder</b> tool will generate a
                        list of tasks that could be part of your products backlog.
                        Just hit the <b>Generate</b> button.
                    </p>
                </div>
                <div className='col-auto'>
                    {data &&
                        <div className='row gx-3'>
                            <div className='col-auto'>
                                <button type="button" onClick={share} className='btn iron bold legend'>
                                    <span className='me-1'><FontAwesomeIcon icon={faCopy} color='#000000' /></span> Copy
                                </button>
                            </div>
                            <div className='col-auto'>
                                <button type="button" onClick={regenerate} className='btn btn-cta-primary bg-apporange white bold legend'>
                                    Update
                                </button>
                            </div>
                        </div>
                    }
                    {!data &&
                        <div className='row mt-2'>
                            <button type="button" onClick={getData} className='btn btn-cta-primary bg-apporange white bold legend'>
                                Generate
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className=''>
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className='container-fluid'>
                        {data &&
                            <div className='row mt-2 gy-3 gx-3'>
                                {data.map((i: any, index: any) => (
                                    <div className='col-auto me-3 p-2 wi-container legend'>
                                        <p className='mb-2 p-2 legend'>{i.description}</p>

                                        <div className='row gy-3'>
                                            <div className='ms-3 col-auto category'>
                                                <span>{i.category === 'bug' ? '🐞' : '💡'}</span> {i.category}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default PredictRoadmapComponent;
