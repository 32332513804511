import React from 'react';
import PublicNavigationHeader from './PublicNavigationHeader';

import footer_appinion from '../resources/footer_logo.png'

function Privacy() {

    return (
        <div>
            <PublicNavigationHeader menu={false} />
            <div className='container col-6'>
                <h1 className='iron semi-bold mt-5'>Appinion Privacy Policy</h1>
                <h5 className='iron mt-5 mb-4'>1. Overview</h5>
                <p>Welcome to the Appinion Privacy Policy. This document outlines how Appinion ("we", "us", or "our") collects, uses, discloses,
                    and protects your personal information when you use our qualitative analysis platform ("Service").
                    By using the Service, you agree to the terms outlined in this Privacy Policy.</p>
                <h5 className='iron mt-5 mb-4'>2. Information We Collect</h5>
                <p>Personal Information: During the registration process, we collect personal information such as name and email address.</p>
                <h5 className='iron mt-5 mb-4'>3. How We Use Your Information</h5>
                <p>We use the collected information for the following purposes:</p>
                <p><li>To provide and maintain the Service.</li></p>
                <p><li>To verify user eligibility and maintain user accounts.</li></p>
                <p><li>To analyze and improve the quality of our Service.</li></p>
                <p><li>To communicate with users, including updates and marketing materials.</li></p>
                <h5 className='iron mt-5 mb-4'>4. Information Sharing and Disclosure</h5>
                <p>We <b>do not</b> sell or share your personal information with third parties except in the following circumstances:</p>
                <p><li>With your explicit consent.</li></p>
                <p><li>To comply with legal obligations.</li></p>
                <p><li>To protect and defend our rights and property.</li></p>
                <h5 className='iron mt-5 mb-4'>5. Data Security</h5>
                <p>We prioritize the security of your information and employ industry-standard measures to protect against unauthorized access or disclosure.</p>
                <h5 className='iron mt-5 mb-4'>6. Your Choices</h5>
                <p>You have the right to access, correct, or delete your personal information. You can update your preferences or unsubscribe from marketing communications.</p>
                <h5 className='iron mt-5 mb-4'>7. Changes to This Privacy Policy</h5>
                <p>We may update this Privacy Policy to reflect changes in our practices. We will notify you of any significant changes via email or through a
                    notice on our website.</p>
                <h5 className='iron mt-5 mb-4'>8. Contact Information</h5>
                <p>For any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:main@appinion.pt">main@appinion.pt</a>.</p>
                <footer className='mt-5 p-3 text-center'>
                    <p className='legend light-iron'>© Appinion 2023. All rights reserved</p>
                    <img className='ms-3' src={footer_appinion} alt="logo" style={{ height: 16 + 'px' }}></img>
                    <ul className='navbar-nav ms-auto'>
                        <li className='nav-item'>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}

export default Privacy;