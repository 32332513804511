import { useState } from 'react';
import React from 'react';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/authentication.scss'
import AlertComponent from '../AlertComponent';

function ContactUsForm() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = async (event: any) => {
        setLoading(true)

        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "eabc3ab5-9e51-4cf6-8873-5ed5c72a99f9");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());
        setLoading(false)

        if (res.success) {
            setSubmitted(true)
            console.log("Success", res);
        }
    };

    return (
        <div>
            {
                loading ?
                    <LoadingSpinnerComponent white={true} />
                    :
                    <form onSubmit={onSubmit}>
                        {submitted === true &&
                            <AlertComponent type={1} alert={"Thank you for reaching out!"} />
                        }
                        <div className="mb-3">
                            <label htmlFor='name' className="white legend bold mb-2 ">Name</label>
                            <input type="text" name="name" className="form-control" id="name" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor='email' className="white legend bold mb-2 ">E-mail</label>
                            <input type="email" name="email" className="form-control" id="email" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor='message' className="white legend bold mb-2 ">Your message</label>
                            <textarea rows={4} name="message" className="form-control" id="message" />
                        </div>

                        <button style={{ width: 100 + "%" }} type="submit" className="btn mt-5 btn-outline">Ask for more info</button>
                    </form>
            }
        </div>
    );
}

export default ContactUsForm;